import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
`;

export const Container = styled.div`
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
`;
