import styled from 'styled-components';
import Checkbox from '@components/Checkbox';

export const FiltersContainer = styled.div`
  padding-left: 20px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
`;

export const Text = styled.div`
  font-family: Source Sans Pro;
  font-size: 18px;
  font-weight: 600;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
  margin: 15px 0 15px 0;
`;

export const StyledCheckbox = styled(Checkbox)<{ checked?: boolean }>`
  background-color: ${(props) => (props.checked ? 'rgba(232, 108, 0, 1)' : '#eee')};
`;
