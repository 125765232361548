import React from 'react';
import { Container, Text } from './styles';

interface Props {
  total?: number;
  validated?: number;
  rejected?: number;
  pending?: number;
  flagged?: number;
  loading?: boolean;
}

const SearchResults: React.FC<Props> = (props) => {
  const { total, validated, rejected, pending, flagged, loading } = props;
  const loadingText = loading ? 'Loading...' : undefined;
  return (
    <Container>
      <Text>
        Total Number of Utilities: {total} {loadingText}
      </Text>
      <Text>
        Validated: {validated} {loadingText}
      </Text>
      <Text>
        Rejected: {rejected} {loadingText}
      </Text>
      <Text>
        Pending Validation: {pending} {loadingText}
      </Text>
      <Text>
        Flagged: {flagged} {loadingText}
      </Text>
    </Container>
  );
};

export default SearchResults;
