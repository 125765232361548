import * as Apollo from '@apollo/client';
import {
  AnalyzedEntity as AnalyzedEntityGenerated,
  GetScansByFilterQuery,
  GetScansByFilterQueryVariables,
  Scan,
  Status
} from '@graphql/generated';

type QueryOptions = Apollo.LazyQueryHookOptions<
  GetScansByFilterQuery,
  GetScansByFilterQueryVariables
>;

export interface Filter {
  country: string;
  project: string;
  site: string;
  domain: string;
  scanName: string;
  startDate: string;
  endDate: string;
  analyzedEntityId: string;
  analyzedEntityFlagged: boolean;
}

export const getQueryOptions = (filter: Filter): QueryOptions => {
  return {
    variables: {
      scanFilter: {
        and: [
          {
            country: { wildcard: `*${filter.country}*` },
            site_name: { wildcard: `*${filter.site}*` },
            scan_name: { wildcard: `*${filter.scanName}*` },
            scan_date: {
              gte: filter.startDate,
              lte: filter.endDate
            }
          }
        ]
      },
      analyzedEntityFilter: {
        and: [
          filter.analyzedEntityFlagged
            ? {
                id: { beginsWith: filter.analyzedEntityId },
                flagged: { eq: filter.analyzedEntityFlagged }
              }
            : {
                id: { beginsWith: filter.analyzedEntityId }
              }
        ]
      }
    }
  };
};

export type PartialAnalyzedEntity = Pick<
  AnalyzedEntityGenerated,
  'status' | 'flagged' | 'id' | 'comments'
>;
export const getAnalyzedEntitiesFromScans = (scans: Scan[]): PartialAnalyzedEntity[] => {
  return (
    scans.reduce<PartialAnalyzedEntity[]>((accumulator, currentValue) => {
      if (!currentValue?.analyzed_entities || currentValue.analyzed_entities.items === null) {
        return accumulator;
      }

      return [...accumulator, ...currentValue.analyzed_entities.items] as PartialAnalyzedEntity[];
    }, []) ?? []
  );
};

export interface AnalyzedEntityFilterOptions {
  approved: boolean;
  rejected: boolean;
  pending: boolean;
  flagged: boolean;
  hasComments: boolean;
}

export const getFilteredAnalyzedEntities = (
  analyzedEntities: PartialAnalyzedEntity[],
  filter: AnalyzedEntityFilterOptions
): PartialAnalyzedEntity[] => {
  let filteredAnalyzedEntities = analyzedEntities;

  if (filter.flagged) {
    filteredAnalyzedEntities = filteredAnalyzedEntities.filter((item) => item.flagged);
  }

  if (filter.approved) {
    filteredAnalyzedEntities = filteredAnalyzedEntities.filter(
      (item) => item.status === Status.Approved
    );
  }

  if (filter.rejected) {
    filteredAnalyzedEntities = filteredAnalyzedEntities.filter(
      (item) => item.status === Status.Rejected
    );
  }

  if (filter.pending) {
    filteredAnalyzedEntities = filteredAnalyzedEntities.filter(
      (item) => item.status === Status.Pending
    );
  }

  if (filter.hasComments) {
    filteredAnalyzedEntities = filteredAnalyzedEntities.filter(
      (item) => item.comments?.items.length
    );
  }

  return filteredAnalyzedEntities;
};

export const getFilterFormURL = (): Filter => {
  const searchParams = new URL(document.location.href).searchParams;
  return {
    country: searchParams.get('country') ?? '',
    project: searchParams.get('project') ?? '',
    site: searchParams.get('site') ?? '',
    domain: searchParams.get('domain') ?? '',
    scanName: searchParams.get('scanName') ?? '',
    startDate: searchParams.get('startDate') ?? '',
    endDate: searchParams.get('endDate') ?? '',
    analyzedEntityId: searchParams.get('analyzedEntityId') ?? '',
    analyzedEntityFlagged: searchParams.get('analyzedEntityFlagged') === 'true'
  };
};
