import React, { ChangeEvent } from 'react';

interface Props {
  value: number;
  max: number;
  min: number;
  onChange?: (value: number) => void;
}

const Slider: React.FC<Props> = (props) => {
  const { value, onChange, min, max } = props;

  const onClickSlider = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange?.(parseInt(e.target.value));
  };

  return <input type="range" min={min} max={max} onChange={onClickSlider} value={value} />;
};

export default Slider;
