import React from 'react';
import { FiltersContainer, Row, StyledCheckbox, Text } from './styles';
import { AnalyzedEntityFilterOptions } from '@helpers/filterData';

interface Props {
  filter: AnalyzedEntityFilterOptions;
  setFilter: (filter: AnalyzedEntityFilterOptions) => void;
}

const Filter: React.FC<Props> = (props) => {
  const { filter, setFilter } = props;

  const setNewFilter = <T extends keyof AnalyzedEntityFilterOptions>(
    key: T,
    value: AnalyzedEntityFilterOptions[T]
  ): void => {
    setFilter({
      ...filter,
      [key]: value
    });
  };

  return (
    <FiltersContainer>
      <Row>
        <StyledCheckbox
          checked={filter.approved}
          onChange={() => setNewFilter('approved', !filter.approved)}
        />
        <Text onClick={() => setNewFilter('approved', !filter.approved)}>Approved</Text>
      </Row>
      <Row>
        <StyledCheckbox
          checked={filter.rejected}
          onChange={() => setNewFilter('rejected', !filter.rejected)}
        />
        <Text onClick={() => setNewFilter('rejected', !filter.rejected)}>Rejected</Text>
      </Row>
      <Row>
        <StyledCheckbox
          checked={filter.pending}
          onChange={() => setNewFilter('pending', !filter.pending)}
        />
        <Text onClick={() => setNewFilter('pending', !filter.pending)}>Pending Validation</Text>
      </Row>
      <Row>
        <StyledCheckbox
          checked={filter.flagged}
          onChange={() => setNewFilter('flagged', !filter.flagged)}
        />
        <Text onClick={() => setNewFilter('flagged', !filter.flagged)}>Flagged</Text>
      </Row>
      <Row>
        <StyledCheckbox
          checked={filter.hasComments}
          onChange={() => setNewFilter('hasComments', !filter.hasComments)}
        />
        <Text onClick={() => setNewFilter('hasComments', !filter.hasComments)}>Have Comments</Text>
      </Row>
    </FiltersContainer>
  );
};

export default Filter;
