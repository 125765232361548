import styled from 'styled-components';

export const TraceText = styled.div`
  font-family: Roboto;
  font-size: 10px;
`;

export const Line = styled.div<{ color?: string }>`
  background-color: ${(props) => props.color};
  height: 4px;
  width: 40px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
