import React, { createContext, useState } from 'react';
import { userData } from '@helpers/auth';

const UserContext = createContext<{
  user: userData | undefined;
  setUser: React.Dispatch<React.SetStateAction<userData | undefined>>;
}>({
  user: undefined,
  setUser: () => {}
});

interface Props {
  children: React.ReactNode;
}

export const UserProvider: React.FC<Props> = (props) => {
  const { children } = props;
  const [user, setUser] = useState<userData>();

  return (
    <UserContext.Provider
      value={{
        user,
        setUser
      }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
