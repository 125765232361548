import React from 'react';
import { createBrowserRouter, RouterProvider, RouteObject } from 'react-router-dom';
import { FILTERS, MAIN } from '@constants/routes';
import Main from '@pages/Main';
import Filters from '@pages/Filters';

const routsArray: RouteObject[] = [
  {
    path: MAIN,
    element: <Main />
  },
  {
    path: FILTERS,
    element: <Filters />
  }
];

const router = createBrowserRouter(routsArray);

const Router: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default Router;
