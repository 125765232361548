import styled from 'styled-components';

export const Container = styled.div`
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 15px;
`;

export const Row = styled.div`
  display: flex;
`;

export const Metric = styled.div`
  width: 50%;
  display: flex;
  gap: 10px;
  margin-top: 15px;
`;

export const Icon = styled.img``;

export const TitleText = styled.div`
  font-family: Roboto;
  font-size: 23px;
  font-weight: 600;
  line-height: 24px;
`;

export const Text = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
`;
