// standard Math.min and Math.max have limits for large arrays

export const getMax = (arr: number[]): number => {
  let len = 1;
  let max = arr[0];

  while (len < arr.length) {
    max = arr[len] > max ? arr[len] : max;
    len++;
  }
  return max;
};

export const getMin = (arr: number[]): number => {
  let len = 1;
  let min = arr[0];

  while (len < arr.length) {
    min = arr[len] < min ? arr[len] : min;
    len++;
  }
  return min;
};
