import styled from 'styled-components';
import { Button } from '@components/Button/styles';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 20px;
`;

export const StyledButton = styled(Button)`
  max-width: 350px;
`;

export const Icon = styled.img``;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
