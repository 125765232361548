import React from 'react';
import { Container, Text } from './styles';

interface Props {
  ids: string[];
  onClick: (id: string) => void;
}

const IdList: React.FC<Props> = (props) => {
  const { ids, onClick } = props;

  return (
    <Container>
      {ids.map((id) => (
        <Text key={id} onClick={() => onClick(id)}>
          {id}
        </Text>
      ))}
    </Container>
  );
};

export default IdList;
