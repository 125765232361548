import React, { useState, useCallback } from 'react';
import { Icon, PageContainer, Row, SearchContainer, StyledButton } from './styles';
import Header from './Header';
import { ButtonTypes } from '@components/Button/styles';
import ApproveIcon from '@assets/approve.svg';
import SearchResults from './SearchResults';
import { useNavigate } from 'react-router-dom';
import { MAIN } from '@constants/routes';
import { getParamsFromObj } from '@helpers/url';
import FilterInputs from './FilterInputs';
import { Status } from '@graphql/generated';
import { debounce } from '@helpers/debounce';
import { useEffectAsync } from '@hooks/useEffectAsync';
import { Filter, getAnalyzedEntitiesFromScans } from '@helpers/filterData';
import { useAllScansByFilterWithCountriesQuery } from '@hooks/useAllScansByFilterWithCountriesQuery';
import { getDatePlusDays, getFormattedDate } from '@helpers/date';

const InitFilter: Filter = {
  country: '',
  project: '',
  site: '',
  domain: '',
  scanName: '',
  startDate: getDatePlusDays(new Date(), -7),
  endDate: getFormattedDate(new Date()),
  analyzedEntityId: '',
  analyzedEntityFlagged: false
};

const Filters: React.FC = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<Filter>(InitFilter);
  const { scans, getScans, countries, loading } = useAllScansByFilterWithCountriesQuery();

  const getDataByFiltersDebounce = useCallback(debounce(getScans, 2000), []);

  useEffectAsync(async () => {
    await getDataByFiltersDebounce(filter);
  }, [filter]);

  const siteOptions: string[] =
    scans.reduce<string[]>((accumulator, currentValue) => {
      if (currentValue?.site_name) {
        return [...accumulator, currentValue.site_name];
      }
      return accumulator;
    }, []) ?? [];

  const scanOptions: string[] =
    scans.reduce<string[]>((accumulator, currentValue) => {
      if (currentValue?.site_name) {
        return [...accumulator, currentValue.scan_name];
      }
      return accumulator;
    }, []) ?? [];

  const analyzedEntities = getAnalyzedEntitiesFromScans(scans);

  const flaggedAnalyzedEntities = analyzedEntities.filter((item) => item.flagged);
  const validatedAnalyzedEntities = analyzedEntities.filter(
    (item) => item.status === Status.Approved
  );
  const rejectedAnalyzedEntities = analyzedEntities.filter(
    (item) => item.status === Status.Rejected
  );
  const pendingAnalyzedEntities = analyzedEntities.filter((item) => item.status === Status.Pending);

  const utilityIdOptions: string[] = analyzedEntities.map((item) => item.id);

  const onStartSession = (): void => {
    const searchParams = getParamsFromObj({ ...filter });
    navigate(`${MAIN}?${searchParams}`);
  };

  return (
    <PageContainer>
      <Header />
      <FilterInputs
        filter={filter}
        setFilter={setFilter}
        countryOptions={countries}
        scanOptions={scanOptions}
        siteOptions={siteOptions}
        utilityIdOptions={utilityIdOptions}
      />
      <SearchContainer>
        <SearchResults
          loading={loading}
          total={analyzedEntities.length}
          validated={validatedAnalyzedEntities.length}
          rejected={rejectedAnalyzedEntities.length}
          pending={pendingAnalyzedEntities.length}
          flagged={flaggedAnalyzedEntities.length}
        />
        <StyledButton
          type={ButtonTypes.approve}
          onClick={onStartSession}
          disabled={!analyzedEntities.length}>
          <Row>
            <Icon src={ApproveIcon} />
            Start Validation Session
          </Row>
        </StyledButton>
      </SearchContainer>
    </PageContainer>
  );
};

export default Filters;
