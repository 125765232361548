import React from 'react';
import { Row, Line, TraceText } from './styles';

interface Props {
  yColor: string;
  zColor: string;
}

const Trace: React.FC<Props> = (props) => {
  const { yColor, zColor } = props;

  return (
    <div>
      <Row>
        <Line color={yColor} />
        <TraceText>yamplitude</TraceText>
      </Row>
      <Row>
        <Line color={zColor} />
        <TraceText>zamplitude</TraceText>
      </Row>
    </div>
  );
};

export default Trace;
