import React, { useContext } from 'react';
import {
  Container,
  UtilityID,
  Icon,
  Text,
  Row,
  LeftContainer,
  RightContainer,
  ArrowForwardIcon
} from './styles';
import CopyIcon from '@assets/copy.png';
import FlagIcon from '@assets/flag.svg';
import ArrowIcon from '@assets/arrow.svg';
import Status, { ApprovalStatus } from './Status';
import Button from '@components/Button';
import UserContext from '@contexts/UserContext';

interface Props {
  utilityId?: string;
  frequency?: number;
  status: ApprovalStatus;
  onClickPrevUtility: () => void;
  onClickNextUtility: () => void;
}

const Header: React.FC<Props> = (props) => {
  const { utilityId, status, frequency, onClickPrevUtility, onClickNextUtility } = props;
  const { user } = useContext(UserContext);
  const copyId = (): void => {
    navigator.clipboard
      .writeText(utilityId ?? '')
      .catch((e) => console.log('error during copying Id', e));
  };
  return (
    <Container>
      <LeftContainer>
        <Row>
          <UtilityID>Utility ID: {utilityId}</UtilityID>
          <Icon src={CopyIcon} onClick={copyId} />
        </Row>
        <Status status={status} />
        <Row>
          <Text>by {user?.account?.username}</Text>
          <Icon src={FlagIcon} />
        </Row>
        {frequency !== undefined && <Text>{frequency} Hz</Text>}
      </LeftContainer>
      <RightContainer>
        <Button onClick={onClickPrevUtility}>
          <Row>
            <Icon src={ArrowIcon} />
            Previous Utility
          </Row>
        </Button>
        <Button onClick={onClickNextUtility}>
          <Row>
            Next Utility <ArrowForwardIcon src={ArrowIcon} />
          </Row>
        </Button>
      </RightContainer>
    </Container>
  );
};

export default Header;
