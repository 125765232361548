import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  min-width: 300px;
`;

export const Arrow = styled.div<{ checked?: boolean }>`
  border: solid #818181;
  display: inline-block;
  border-width: 0 2px 2px 0;
  padding: 4px;
  top: ${(props) => (props.checked ? '16px' : '10px')};
  right: 10px;
  position: absolute;
  transform: ${(props) => (props.checked ? 'rotate(-135deg)' : 'rotate(45deg)')};
  -webkit-transform: ${(props) => (props.checked ? 'rotate(-135deg)' : 'rotate(45deg)')};
`;

export const Input = styled.input`
  border-radius: 4px;
  border: 1px solid #000;
  padding: 15px 10px 0 10px;
  width: 100%;
  height: 36px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
`;

export const Label = styled.label`
  font-size: 16px;
  font-weight: 300;
  position: absolute;
  left: 10px;
  user-select: none;
`;

export const Dropdown = styled.div`
  position: absolute;
  width: 100%;
  height: 150px;
  border-radius: 4px;
  border: 1px solid #000;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;
  background-color: #fff;
`;
export const DropdownItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;
`;
