import React, { useState, useContext } from 'react';
import './App.css';
import { logIn } from '@helpers/auth';
import { useEffectAsync } from '@hooks/useEffectAsync';
import Router from '@routing/Router';
import { ApolloProvider } from '@apollo/client';
import client from '@graphql/client';
import UserContext from '@contexts/UserContext';

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { setUser } = useContext(UserContext);

  useEffectAsync(async () => {
    const user = await logIn();
    setUser(user);
    setIsLoggedIn(true);
    console.log(user);
  }, []);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      <Router />
    </ApolloProvider>
  );
};

export default App;
