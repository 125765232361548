import React from 'react';
import { Metric, Row, Text, TitleText, Icon, Container } from './styles';
import InfoIcon from '@assets/info.svg';

interface Props {
  crossingsQty?: number | string;
  correlationFactor?: number | string;
  meanCrossingAngle?: number | string;
  meanCrossingPower?: number | string;
}

const Metrics: React.FC<Props> = (props) => {
  const { crossingsQty, correlationFactor, meanCrossingAngle, meanCrossingPower } = props;
  return (
    <Container>
      <TitleText>Metrics</TitleText>
      <Row>
        <Metric>
          <Text>N_CROSSINGS: {crossingsQty}</Text>
        </Metric>
        <Metric>
          <Text>CORRELATION_FACTOR: {correlationFactor}</Text>
          <Icon src={InfoIcon} />
        </Metric>
      </Row>
      <Row>
        <Metric>
          <Text>MEAN_CROSSING_ANGLE: {meanCrossingAngle}</Text>
          <Icon src={InfoIcon} />
        </Metric>
        <Metric>
          <Text>MEAN_CROSSING_POWER: {meanCrossingPower}</Text>
          <Icon src={InfoIcon} />
        </Metric>
      </Row>
    </Container>
  );
};

export default Metrics;
