import React, { useEffect, useRef } from 'react';
import * as Plotly from 'plotly.js';
import { Container } from './styles';

interface Props {
  x: number[];
  yamplitude: number[];
  zamplitude: number[];
  yColor: string;
  zColor: string;
}

const layout: Partial<Plotly.Layout> = {
  showlegend: false,
  xaxis: {
    visible: false
  },
  yaxis: {
    visible: false
  },
  margin: {
    t: 0,
    b: 0,
    l: 0,
    r: 0,
    pad: 0
  },
  width: 40,
  height: 450
};

const config: Partial<Plotly.Config> = {
  displayModeBar: false
};

const Graph: React.FC<Props> = (props) => {
  const { x, yamplitude, zamplitude, yColor, zColor } = props;
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const plotElement = ref.current;
    if (plotElement) {
      void Plotly.newPlot(
        plotElement,
        [
          {
            x,
            y: yamplitude,
            mode: 'lines',
            line: {
              color: yColor
            }
          },
          {
            x,
            y: zamplitude,
            mode: 'lines',
            line: {
              color: zColor
            }
          }
        ],
        layout,
        config
      );
    }
  }, []);

  return <Container ref={ref} />;
};

export default Graph;
