import React from 'react';
import {
  Container,
  CrossingViewsContainer,
  TitleText,
  ApproveAndRejectContainer,
  ButtonsContainer,
  GraphContainer,
  Row
} from './styles';
import Button from '@components/Button';
import Graph from './Graph';
import Checkbox from '@components/Checkbox';
import { Status as StatusType } from '@graphql/generated';
import Status from './Status';
import Trace from '@pages/Main/DetailsView/CrossingViews/Trace';

export interface Signal {
  id: number;
  signal: number[];
}

interface Props {
  onApprove: () => void;
  onReject: () => void;
  onSelectNone: () => void;
  onSelectAll: () => void;
  yamplitude?: Signal[];
  zamplitude?: Signal[];
  crossingStatuses?: Array<StatusType | null>;
  checkedGraphIds: number[];
  onCheckGraph: (index: number) => void;
  approveRejectLoading?: boolean;
}

const yColor = '#ff8b08';
const zColor = '#562eff';

const CrossingViews: React.FC<Props> = (props) => {
  const {
    onApprove,
    onReject,
    onSelectNone,
    onSelectAll,
    yamplitude,
    zamplitude,
    onCheckGraph,
    checkedGraphIds,
    crossingStatuses,
    approveRejectLoading
  } = props;

  return (
    <Container>
      <Row>
        <TitleText>Crossings View</TitleText>
        <Trace yColor={yColor} zColor={zColor} />
      </Row>
      <CrossingViewsContainer>
        {zamplitude &&
          yamplitude?.map((data, index) => (
            <GraphContainer key={data.id}>
              <Graph
                yColor={yColor}
                zColor={zColor}
                yamplitude={data.signal}
                zamplitude={zamplitude[index].signal}
                x={data.signal.map((item, index) => index)}
              />
              {crossingStatuses && <Status status={crossingStatuses[index]} />}
              <Checkbox
                onChange={() => onCheckGraph(index)}
                checked={checkedGraphIds.includes(index)}
              />
            </GraphContainer>
          ))}
      </CrossingViewsContainer>
      <ButtonsContainer>
        {checkedGraphIds.length ? (
          <Button onClick={onSelectNone}>Select None</Button>
        ) : (
          <Button onClick={onSelectAll}>Select All</Button>
        )}
        <ApproveAndRejectContainer>
          <Button onClick={onApprove} disabled={approveRejectLoading}>
            Approve
          </Button>
          <Button onClick={onReject} disabled={approveRejectLoading}>
            Reject
          </Button>
        </ApproveAndRejectContainer>
      </ButtonsContainer>
    </Container>
  );
};

export default CrossingViews;
