import styled from 'styled-components';

export const NavContainer = styled.div`
  width: 80px;
  min-width: 80px;
  position: relative;
`;

export const Nav = styled.div<{ $isOpen?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 10000; // needed to cover the map;
  width: ${(props) => (props.$isOpen ? '300px' : '80px')};
  transition: 0.5s;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 1px 10px 0 rgba(0, 0, 0, 0.09),
    0 4px 5px 0 rgba(0, 0, 0, 0.11);
`;
