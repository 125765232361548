import styled from 'styled-components';

export const Icon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export const Container = styled.div`
  overflow: hidden;
  height: 100%;
  overflow-y: auto;
`;
export const HomeContainer = styled.div`
  display: flex;
  padding-left: 20px;
  align-items: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  height: 100px;
`;
