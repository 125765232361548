import React from 'react';
import { Select } from './styles';

export interface Option {
  key: string;
  value: string;
}

interface Props {
  selectedKey: Option['key'];
  onChange?: (key: Option['key']) => void;
  options?: Option[];
}

const SelectInput: React.FC<Props> = (props) => {
  const { selectedKey, onChange, options } = props;

  const onChangeInput = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    onChange?.(e.target.value);
  };

  return (
    <Select value={selectedKey} onChange={onChangeInput}>
      {options?.map((opt) => (
        <option value={opt.key} key={opt.key}>
          {opt.value}
        </option>
      ))}
    </Select>
  );
};

export default SelectInput;
