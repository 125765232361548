import styled from 'styled-components';

export const Container = styled.div`
  height: 100px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const LeftContainer = styled.div`
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const RightContainer = styled.div`
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const UtilityID = styled.div`
  color: rgba(57, 68, 82, 1);
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  max-width: 400px;
  word-break: break-all;
`;

export const Text = styled.div`
  color: #272833;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export const Icon = styled.img`
  cursor: pointer;

  &:hover {
    filter: brightness(85%);
  }
`;

export const ArrowForwardIcon = styled(Icon)`
  transform: rotate(180deg);
`;
