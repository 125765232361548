import styled from 'styled-components';

export const StatusButtonsContainer = styled.div`
  width: 45%;
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Icon = styled.img``;
