import React from 'react';
import { GeoJSON } from 'react-leaflet';
import { GeoJsonObject } from 'geojson';

interface Props {
  utilityData?: GeoJsonObject;
}

const scanPathStyle = {
  color: 'black'
};

const Utility: React.FC<Props> = (props) => {
  const { utilityData } = props;

  if (utilityData === undefined) {
    return null;
  }

  return <GeoJSON data={utilityData} style={scanPathStyle} />;
};

export default Utility;
