import React from 'react';
import { Container } from './styles';
import MapLayerToggles from './MapLayerToggles';
import StatusButtons from './StatusButtons';
import { Option } from '@components/SelectInput';

interface Props {
  isMapLayerOn: boolean;
  onChangeMapLayerOn: (value: boolean) => void;
  isOrthophotoLayerOn: boolean;
  onChangeOrthophotoLayerOn: (value: boolean) => void;
  isScanPathLayerOn: boolean;
  onChangeScanPathLayerOn: (value: boolean) => void;
  isUtilitiesLayerOn: boolean;
  onChangeUtilitiesLayerOn: (value: boolean) => void;

  interpolationOptions: Option[];
  selectedInterpolationId: Option['key'];
  setSelectedInterpolationId: (key: Option['key']) => void;

  onApprove: () => void;
  onFlag: () => void;
  onReject: () => void;
}

const ControlButtons: React.FC<Props> = (props) => {
  const {
    isMapLayerOn,
    onChangeMapLayerOn,
    isOrthophotoLayerOn,
    onChangeOrthophotoLayerOn,
    isScanPathLayerOn,
    onChangeScanPathLayerOn,
    isUtilitiesLayerOn,
    onChangeUtilitiesLayerOn,

    interpolationOptions,
    selectedInterpolationId,
    setSelectedInterpolationId,

    onApprove,
    onFlag,
    onReject
  } = props;

  return (
    <Container>
      <MapLayerToggles
        isMapLayerOn={isMapLayerOn}
        onChangeMapLayerOn={onChangeMapLayerOn}
        isOrthophotoLayerOn={isOrthophotoLayerOn}
        onChangeOrthophotoLayerOn={onChangeOrthophotoLayerOn}
        isScanPathLayerOn={isScanPathLayerOn}
        onChangeScanPathLayerOn={onChangeScanPathLayerOn}
        isUtilitiesLayerOn={isUtilitiesLayerOn}
        onChangeUtilitiesLayerOn={onChangeUtilitiesLayerOn}
        interpolationOptions={interpolationOptions}
        selectedInterpolationId={selectedInterpolationId}
        setSelectedInterpolationId={setSelectedInterpolationId}
      />
      <StatusButtons onApprove={onApprove} onFlag={onFlag} onReject={onReject} />
    </Container>
  );
};

export default ControlButtons;
