import React, { useState } from 'react';
import Map, { InterpolationData } from '@components/Map';
import { GeoJsonObject } from 'geojson';
import { useEffectAsync } from '@hooks/useEffectAsync';
import { Container, Row, SliderText, SubTitleText, TitleContainer, TitleText } from './styles';
import { fetchDataByUrl } from '@helpers/fetchData';
import Slider from '@components/Slider';

const centerLat = 40.820410399000004;
const centerLng = -72.957328127;

interface Props {
  scanName?: string;
  siteName?: string;
  scanDate?: string;
  isMapLayerOn: boolean;
  isOrthophotoLayerOn: boolean;
  isInterpolationLayerOn: boolean;
  isScanPathLayerOn: boolean;
  isUtilitiesLayerOn: boolean;
  scanPathUrl?: string;
  interpolationUrl?: string;
  utilityData?: GeoJsonObject;
}

const MIN_SLIDER_VALUE = 0;
const MAX_SLIDER_VALUE = 100;

const MapView: React.FC<Props> = (props) => {
  const {
    scanName,
    siteName,
    scanDate,
    isMapLayerOn,
    isOrthophotoLayerOn,
    isUtilitiesLayerOn,
    isInterpolationLayerOn,
    isScanPathLayerOn,
    scanPathUrl,
    interpolationUrl,
    utilityData
  } = props;
  const [interpolationData, setInterpolationData] = useState<InterpolationData>();
  const [scanPathData, setScanPathData] = useState<GeoJsonObject>();
  const [maxSlider, setMaxSlider] = useState<number>(MAX_SLIDER_VALUE);
  const [minSlider, setMinSlider] = useState<number>(MIN_SLIDER_VALUE);

  useEffectAsync(async () => {
    if (!scanPathUrl) {
      return;
    }

    const data = await fetchDataByUrl<GeoJsonObject>(scanPathUrl);
    setScanPathData(data);
  }, [scanPathUrl]);

  useEffectAsync(async () => {
    if (!interpolationUrl) {
      return;
    }

    const data = await fetchDataByUrl<InterpolationData>(interpolationUrl);
    setInterpolationData(data);
  }, [interpolationUrl]);

  return (
    <Container>
      <TitleContainer>
        <TitleText>{siteName}</TitleText>
        <TitleText>{scanDate}</TitleText>
      </TitleContainer>
      <Row>
        <SubTitleText>{scanName}</SubTitleText>
        <Row>
          <Slider
            max={MAX_SLIDER_VALUE}
            min={MIN_SLIDER_VALUE}
            value={maxSlider}
            onChange={setMaxSlider}
          />
          <SliderText>maxZ: {maxSlider} %</SliderText>
        </Row>
        <Row>
          <Slider
            max={MAX_SLIDER_VALUE}
            min={MIN_SLIDER_VALUE}
            value={minSlider}
            onChange={setMinSlider}
          />
          <SliderText>minZ: {minSlider} %</SliderText>
        </Row>
      </Row>

      <Map
        isMapLayerOn={isMapLayerOn}
        isScanPathLayerOn={isScanPathLayerOn}
        isOrthophotoLayerOn={isOrthophotoLayerOn}
        isUtilitiesLayerOn={isUtilitiesLayerOn}
        isInterpolationLayerOn={isInterpolationLayerOn}
        interpolationData={interpolationData}
        scanPathData={scanPathData}
        utilityData={utilityData}
        center={[centerLat, centerLng]}
        maxInterpolation={maxSlider / 100}
        minInterpolation={minSlider / 100}
      />
    </Container>
  );
};

export default MapView;
