import React from 'react';
import { Container, Icon, Row, Text } from './styles';
import Button from '@components/Button';
import CommentsIcon from '@assets/comments.svg';

interface Props {
  commentsQty?: number;
}

const Feedback: React.FC<Props> = (props) => {
  const { commentsQty } = props;
  return (
    <Container>
      <Text>Probability: Highest</Text>
      <Button>
        <Row>
          <Icon src={CommentsIcon} />
          Comment ({commentsQty})
        </Row>
      </Button>
    </Container>
  );
};

export default Feedback;
