import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px;
  width: 55%;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleText = styled.div`
  font-family: Roboto;
  font-size: 23px;
  font-weight: 600;
  line-height: 24px;
`;

export const SubTitleText = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.7px;
`;

export const SliderText = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
