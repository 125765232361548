import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 15px;
  padding-bottom: 30px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
`;

export const CrossingViewsContainer = styled.div`
  margin-top: 15px;
  height: 515px;
  display: flex;
  gap: 4px;
  overflow-x: auto;
  overflow-y: hidden;
`;

export const TitleText = styled.div`
  font-family: Roboto;
  font-size: 23px;
  font-weight: 600;
  line-height: 24px;
`;

export const ButtonsContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
`;

export const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

export const ApproveAndRejectContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-around;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
