import React from 'react';
import { LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer } from 'react-leaflet';
import Interpolation, { InterpolationZ } from './Interpolation';
import ScanPath from './ScanPath';
import Utility from './Utility';
import { GeoJsonObject } from 'geojson';
import ZoomLocationController from './ZoomLocationController';

export interface InterpolationData {
  bbox_transformed: [[number, number], [number, number]];
  bbox_original: [[number, number], [number, number]];
  interpolation: InterpolationZ;
}

interface Props {
  interpolationData?: InterpolationData;
  scanPathData?: GeoJsonObject;
  utilityData?: GeoJsonObject;
  center?: LatLngExpression;
  isMapLayerOn: boolean;
  isOrthophotoLayerOn: boolean;
  isInterpolationLayerOn: boolean;
  isScanPathLayerOn: boolean;
  isUtilitiesLayerOn: boolean;
  minInterpolation: number;
  maxInterpolation: number;
}

const mapConfig = {
  zoom: 4,
  maxZoom: 30,
  maxNativeZoom: 18
};

const Map: React.FC<Props> = (props) => {
  const {
    interpolationData,
    scanPathData,
    utilityData,
    center,
    isMapLayerOn,
    isScanPathLayerOn,
    isOrthophotoLayerOn,
    isUtilitiesLayerOn,
    isInterpolationLayerOn,
    maxInterpolation,
    minInterpolation
  } = props;
  console.log(isMapLayerOn, isOrthophotoLayerOn);

  return (
    <MapContainer
      center={center}
      zoom={mapConfig.zoom}
      style={{
        height: '95%',
        width: '100%'
      }}
      maxZoom={mapConfig.maxZoom}>
      <TileLayer
        maxZoom={mapConfig.maxZoom}
        maxNativeZoom={mapConfig.maxNativeZoom}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ZoomLocationController latlng={interpolationData?.bbox_transformed[0]} zoom={20} />
      {isInterpolationLayerOn && interpolationData && (
        <Interpolation
          transformedBox={interpolationData.bbox_transformed}
          interpolation={interpolationData.interpolation}
          opacity={0.8}
          maxInterpolation={maxInterpolation}
          minInterpolation={minInterpolation}
        />
      )}
      {isScanPathLayerOn && <ScanPath scanPathData={scanPathData} />}
      {isUtilitiesLayerOn && <Utility utilityData={utilityData} />}
    </MapContainer>
  );
};

export default Map;
