import React from 'react';
import {
  CheckboxWithMargin,
  DateInputWithMargin,
  DropdownInputWithMargin,
  Container
} from './styles';
import { Filter } from '@helpers/filterData';

interface Props {
  filter: Filter;
  setFilter: (value: Filter) => void;
  countryOptions: string[];
  siteOptions: string[];
  scanOptions: string[];
  utilityIdOptions: string[];
}

const FilterInputs: React.FC<Props> = (props) => {
  const { filter, setFilter, countryOptions, siteOptions, scanOptions, utilityIdOptions } = props;

  const setNewFilter = <T extends keyof Filter>(key: T, value: Filter[T]): void => {
    setFilter({
      ...filter,
      [key]: value
    });
  };

  return (
    <Container>
      <DropdownInputWithMargin
        options={countryOptions}
        value={filter.country}
        onChange={(value) => setNewFilter('country', value)}
        id={'country'}
        label={'Country'}
      />
      <DropdownInputWithMargin
        options={[]}
        value={filter.project}
        onChange={(value) => setNewFilter('project', value)}
        id={'project'}
        label={'Project'}
      />
      <DropdownInputWithMargin
        options={siteOptions}
        value={filter.site}
        onChange={(value) => setNewFilter('site', value)}
        id={'site'}
        label={'Site'}
      />
      <DropdownInputWithMargin
        options={[]}
        value={filter.domain}
        onChange={(value) => setNewFilter('domain', value)}
        id={'domain'}
        label={'Domain'}
      />
      <DropdownInputWithMargin
        options={scanOptions}
        value={filter.scanName}
        onChange={(value) => setNewFilter('scanName', value)}
        id={'scan'}
        label={'Scan'}
      />
      <DateInputWithMargin
        value={filter.startDate}
        onChange={(value) => setNewFilter('startDate', value)}
        id={'start-date'}
        label={'Start Date'}
      />
      <DateInputWithMargin
        value={filter.endDate}
        onChange={(value) => setNewFilter('endDate', value)}
        id={'end-date'}
        label={'End Date'}
      />
      <DropdownInputWithMargin
        options={utilityIdOptions}
        value={filter.analyzedEntityId}
        onChange={(value) => setNewFilter('analyzedEntityId', value)}
        id={'utility-id'}
        label={'Utility ID(s)'}
      />
      <CheckboxWithMargin
        onChange={(value) => setNewFilter('analyzedEntityFlagged', value)}
        checked={filter.analyzedEntityFlagged}
        label={'Only Flagged'}
      />
    </Container>
  );
};

export default FilterInputs;
