import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 4px;
  border: 1px solid #000;
  padding: 2px 8px;
  background: #008744;
  font-family: Source Sans Pro;
  font-weight: 600;
  line-height: 24px;
`;
