import React, { useState } from 'react';
import { Container, HomeContainer, Icon } from './styles';
import HomeIcon from '@assets/home.svg';
import Filter from './Filter';
import { useNavigate } from 'react-router-dom';
import { FILTERS } from '@constants/routes';
import {
  getFilteredAnalyzedEntities,
  AnalyzedEntityFilterOptions,
  PartialAnalyzedEntity
} from '@helpers/filterData';
import IdList from '@pages/Main/NavigationBarContent/IdList';

const InitFilter: AnalyzedEntityFilterOptions = {
  approved: false,
  rejected: false,
  pending: false,
  flagged: false,
  hasComments: false
};

interface Props {
  isOpen?: boolean;
  analyzedEntities: PartialAnalyzedEntity[];
  setId: (id: string) => void;
}

const NavigationBarContent: React.FC<Props> = (props) => {
  const { isOpen, analyzedEntities, setId } = props;
  const navigate = useNavigate();
  const [filter, setFilter] = useState<AnalyzedEntityFilterOptions>(InitFilter);

  const onClickHome = (): void => navigate(FILTERS);
  const filteredAnalyzedEntities = getFilteredAnalyzedEntities(analyzedEntities, filter);
  const ids = filteredAnalyzedEntities.map((item) => item.id);

  return (
    <Container>
      <HomeContainer>
        <Icon src={HomeIcon} onClick={onClickHome} />
      </HomeContainer>
      {isOpen && (
        <>
          <Filter filter={filter} setFilter={setFilter} />
          <IdList ids={ids} onClick={setId} />
        </>
      )}
    </Container>
  );
};

export default NavigationBarContent;
