import styled from 'styled-components';
import { Status as StatusType } from '@graphql/generated';

const colors = {
  [StatusType.Approved]: 'green',
  [StatusType.Rejected]: 'red',
  [StatusType.Test]: 'gray',
  [StatusType.Pending]: 'gray'
};

const getColor = (status?: StatusType | null): string => {
  const defaultColor = 'grey';

  if (!status) {
    return defaultColor;
  }

  const color = colors[status];

  return color || defaultColor;
};

export const Container = styled.div<{ $status?: StatusType | null }>`
  width: 100%;
  height: 4px;
  background-color: ${(props) => getColor(props.$status)};
`;
