import React from 'react';
import { GeoJSON } from 'react-leaflet';
import { GeoJsonObject } from 'geojson';
import { PathOptions } from 'leaflet';

interface Props {
  scanPathData?: GeoJsonObject;
}

const scanPathStyle: PathOptions = {
  color: 'black',
  dashArray: '5, 5',
  weight: 1
};

const ScanPath: React.FC<Props> = (props) => {
  const { scanPathData } = props;

  if (scanPathData === undefined) {
    return null;
  }

  return <GeoJSON data={scanPathData} style={scanPathStyle} />;
};

export default ScanPath;
