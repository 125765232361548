import styled from 'styled-components';

export const MapButtonsContainer = styled.div`
  width: 55%;
  min-width: 55%;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  align-items: center;
`;

export const Text = styled.div`
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;
