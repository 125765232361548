import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AWSDate: { input: any; output: any; }
  AWSDateTime: { input: any; output: any; }
  AWSEmail: { input: any; output: any; }
  AWSIPAddress: { input: any; output: any; }
  AWSJSON: { input: any; output: any; }
  AWSPhone: { input: any; output: any; }
  AWSTime: { input: any; output: any; }
  AWSTimestamp: { input: any; output: any; }
  AWSURL: { input: any; output: any; }
  BigInt: { input: any; output: any; }
  Double: { input: any; output: any; }
};

export enum Algorithm {
  CorrelationFactor = 'CORRELATION_FACTOR',
  MeanCrossingAngle = 'MEAN_CROSSING_ANGLE',
  MeanCrossingPower = 'MEAN_CROSSING_POWER',
  NCrossings = 'N_CROSSINGS'
}

export type AnalyzedEntity = {
  __typename?: 'AnalyzedEntity';
  algorithm?: Maybe<Algorithm>;
  changes?: Maybe<ModelChangeConnection>;
  comments?: Maybe<ModelCommentConnection>;
  confidence?: Maybe<Confidence>;
  createdAt: Scalars['AWSDateTime']['output'];
  crossings?: Maybe<ModelCrossingConnection>;
  flagged?: Maybe<Scalars['Boolean']['output']>;
  frequency?: Maybe<Scalars['Float']['output']>;
  geometry?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  mean_angle_crossings?: Maybe<Scalars['Float']['output']>;
  n_crossings?: Maybe<Scalars['Int']['output']>;
  prefix?: Maybe<Scalars['String']['output']>;
  probability?: Maybe<Scalars['Float']['output']>;
  scan?: Maybe<Scan>;
  scanAnalyzed_entitiesId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<Status>;
  updatedAt: Scalars['AWSDateTime']['output'];
};


export type AnalyzedEntityChangesArgs = {
  filter?: InputMaybe<ModelChangeFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type AnalyzedEntityCommentsArgs = {
  filter?: InputMaybe<ModelCommentFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type AnalyzedEntityCrossingsArgs = {
  filter?: InputMaybe<ModelCrossingFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type Change = {
  __typename?: 'Change';
  analyzedEntityChangesId?: Maybe<Scalars['ID']['output']>;
  analyzed_entity?: Maybe<AnalyzedEntity>;
  change?: Maybe<Scalars['String']['output']>;
  changed_by?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  timestamp?: Maybe<Scalars['AWSTimestamp']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type Comment = {
  __typename?: 'Comment';
  analyzedEntityCommentsId?: Maybe<Scalars['ID']['output']>;
  analyzed_entity?: Maybe<AnalyzedEntity>;
  changed_by?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['AWSTimestamp']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export enum Confidence {
  High = 'HIGH',
  Highest = 'HIGHEST',
  Low = 'LOW',
  Lowest = 'LOWEST',
  Medium = 'MEDIUM',
  None = 'NONE',
  NoPipe = 'NO_PIPE'
}

export type CreateAnalyzedEntityInput = {
  algorithm?: InputMaybe<Algorithm>;
  confidence?: InputMaybe<Confidence>;
  flagged?: InputMaybe<Scalars['Boolean']['input']>;
  frequency?: InputMaybe<Scalars['Float']['input']>;
  geometry?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  mean_angle_crossings?: InputMaybe<Scalars['Float']['input']>;
  n_crossings?: InputMaybe<Scalars['Int']['input']>;
  prefix?: InputMaybe<Scalars['String']['input']>;
  probability?: InputMaybe<Scalars['Float']['input']>;
  scanAnalyzed_entitiesId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Status>;
};

export type CreateChangeInput = {
  analyzedEntityChangesId?: InputMaybe<Scalars['ID']['input']>;
  change?: InputMaybe<Scalars['String']['input']>;
  changed_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  timestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
};

export type CreateCommentInput = {
  analyzedEntityCommentsId?: InputMaybe<Scalars['ID']['input']>;
  changed_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
};

export type CreateCrossingInput = {
  analyzedEntityCrossingsId?: InputMaybe<Scalars['ID']['input']>;
  checked?: InputMaybe<Array<InputMaybe<Status>>>;
  crossing_type: Scalars['String']['input'];
  crossing_url: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateInterpolationInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  interpolation_type: Scalars['String']['input'];
  interpolation_url: Scalars['String']['input'];
  scanInterpolationsId?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateScanInput = {
  country: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  orthophoto_layer_url?: InputMaybe<Scalars['String']['input']>;
  scan_date: Scalars['AWSDate']['input'];
  scan_name: Scalars['String']['input'];
  scan_path: Scalars['String']['input'];
  scan_path_url?: InputMaybe<Scalars['String']['input']>;
  sensor?: InputMaybe<Scalars['String']['input']>;
  site_name: Scalars['String']['input'];
  timestamp_utc?: InputMaybe<Scalars['String']['input']>;
};

export type Crossing = {
  __typename?: 'Crossing';
  analyzedEntityCrossingsId?: Maybe<Scalars['ID']['output']>;
  analyzed_entity: AnalyzedEntity;
  checked?: Maybe<Array<Maybe<Status>>>;
  createdAt: Scalars['AWSDateTime']['output'];
  crossing_type: Scalars['String']['output'];
  crossing_url: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type DeleteAnalyzedEntityInput = {
  id: Scalars['ID']['input'];
};

export type DeleteChangeInput = {
  id: Scalars['ID']['input'];
};

export type DeleteCommentInput = {
  id: Scalars['ID']['input'];
};

export type DeleteCrossingInput = {
  id: Scalars['ID']['input'];
};

export type DeleteInterpolationInput = {
  id: Scalars['ID']['input'];
};

export type DeleteScanInput = {
  id: Scalars['ID']['input'];
};

export type Interpolation = {
  __typename?: 'Interpolation';
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  interpolation_type: Scalars['String']['output'];
  interpolation_url: Scalars['String']['output'];
  scan: Scan;
  scanInterpolationsId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
};

export type ModelAlgorithmInput = {
  eq?: InputMaybe<Algorithm>;
  ne?: InputMaybe<Algorithm>;
};

export type ModelAnalyzedEntityConditionInput = {
  algorithm?: InputMaybe<ModelAlgorithmInput>;
  and?: InputMaybe<Array<InputMaybe<ModelAnalyzedEntityConditionInput>>>;
  confidence?: InputMaybe<ModelConfidenceInput>;
  flagged?: InputMaybe<ModelBooleanInput>;
  frequency?: InputMaybe<ModelFloatInput>;
  geometry?: InputMaybe<ModelStringInput>;
  mean_angle_crossings?: InputMaybe<ModelFloatInput>;
  n_crossings?: InputMaybe<ModelIntInput>;
  not?: InputMaybe<ModelAnalyzedEntityConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelAnalyzedEntityConditionInput>>>;
  prefix?: InputMaybe<ModelStringInput>;
  probability?: InputMaybe<ModelFloatInput>;
  scanAnalyzed_entitiesId?: InputMaybe<ModelIdInput>;
  status?: InputMaybe<ModelStatusInput>;
};

export type ModelAnalyzedEntityConnection = {
  __typename?: 'ModelAnalyzedEntityConnection';
  items: Array<Maybe<AnalyzedEntity>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelAnalyzedEntityFilterInput = {
  algorithm?: InputMaybe<ModelAlgorithmInput>;
  and?: InputMaybe<Array<InputMaybe<ModelAnalyzedEntityFilterInput>>>;
  confidence?: InputMaybe<ModelConfidenceInput>;
  flagged?: InputMaybe<ModelBooleanInput>;
  frequency?: InputMaybe<ModelFloatInput>;
  geometry?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  mean_angle_crossings?: InputMaybe<ModelFloatInput>;
  n_crossings?: InputMaybe<ModelIntInput>;
  not?: InputMaybe<ModelAnalyzedEntityFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelAnalyzedEntityFilterInput>>>;
  prefix?: InputMaybe<ModelStringInput>;
  probability?: InputMaybe<ModelFloatInput>;
  scanAnalyzed_entitiesId?: InputMaybe<ModelIdInput>;
  status?: InputMaybe<ModelStatusInput>;
};

export enum ModelAttributeTypes {
  Null = '_null',
  Binary = 'binary',
  BinarySet = 'binarySet',
  Bool = 'bool',
  List = 'list',
  Map = 'map',
  Number = 'number',
  NumberSet = 'numberSet',
  String = 'string',
  StringSet = 'stringSet'
}

export type ModelBooleanInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ModelChangeConditionInput = {
  analyzedEntityChangesId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelChangeConditionInput>>>;
  change?: InputMaybe<ModelStringInput>;
  changed_by?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelChangeConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelChangeConditionInput>>>;
  timestamp?: InputMaybe<ModelIntInput>;
};

export type ModelChangeConnection = {
  __typename?: 'ModelChangeConnection';
  items: Array<Maybe<Change>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelChangeFilterInput = {
  analyzedEntityChangesId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelChangeFilterInput>>>;
  change?: InputMaybe<ModelStringInput>;
  changed_by?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelChangeFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelChangeFilterInput>>>;
  timestamp?: InputMaybe<ModelIntInput>;
};

export type ModelCommentConditionInput = {
  analyzedEntityCommentsId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelCommentConditionInput>>>;
  changed_by?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelCommentConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelCommentConditionInput>>>;
  text?: InputMaybe<ModelStringInput>;
  timestamp?: InputMaybe<ModelIntInput>;
};

export type ModelCommentConnection = {
  __typename?: 'ModelCommentConnection';
  items: Array<Maybe<Comment>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelCommentFilterInput = {
  analyzedEntityCommentsId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelCommentFilterInput>>>;
  changed_by?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelCommentFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelCommentFilterInput>>>;
  text?: InputMaybe<ModelStringInput>;
  timestamp?: InputMaybe<ModelIntInput>;
};

export type ModelConfidenceInput = {
  eq?: InputMaybe<Confidence>;
  ne?: InputMaybe<Confidence>;
};

export type ModelCrossingConditionInput = {
  analyzedEntityCrossingsId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelCrossingConditionInput>>>;
  checked?: InputMaybe<ModelStatusListInput>;
  crossing_type?: InputMaybe<ModelStringInput>;
  crossing_url?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelCrossingConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelCrossingConditionInput>>>;
};

export type ModelCrossingConnection = {
  __typename?: 'ModelCrossingConnection';
  items: Array<Maybe<Crossing>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelCrossingFilterInput = {
  analyzedEntityCrossingsId?: InputMaybe<ModelIdInput>;
  and?: InputMaybe<Array<InputMaybe<ModelCrossingFilterInput>>>;
  checked?: InputMaybe<ModelStatusListInput>;
  crossing_type?: InputMaybe<ModelStringInput>;
  crossing_url?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelCrossingFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelCrossingFilterInput>>>;
};

export type ModelFloatInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  ge?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  le?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
};

export type ModelIdInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  beginsWith?: InputMaybe<Scalars['ID']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  ge?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  le?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
  size?: InputMaybe<ModelSizeInput>;
};

export type ModelIntInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  ge?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  le?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
};

export type ModelInterpolationConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelInterpolationConditionInput>>>;
  interpolation_type?: InputMaybe<ModelStringInput>;
  interpolation_url?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelInterpolationConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelInterpolationConditionInput>>>;
  scanInterpolationsId?: InputMaybe<ModelIdInput>;
};

export type ModelInterpolationConnection = {
  __typename?: 'ModelInterpolationConnection';
  items: Array<Maybe<Interpolation>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelInterpolationFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelInterpolationFilterInput>>>;
  id?: InputMaybe<ModelIdInput>;
  interpolation_type?: InputMaybe<ModelStringInput>;
  interpolation_url?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelInterpolationFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelInterpolationFilterInput>>>;
  scanInterpolationsId?: InputMaybe<ModelIdInput>;
};

export type ModelScanConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelScanConditionInput>>>;
  country?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelScanConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelScanConditionInput>>>;
  orthophoto_layer_url?: InputMaybe<ModelStringInput>;
  scan_date?: InputMaybe<ModelStringInput>;
  scan_name?: InputMaybe<ModelStringInput>;
  scan_path?: InputMaybe<ModelStringInput>;
  scan_path_url?: InputMaybe<ModelStringInput>;
  sensor?: InputMaybe<ModelStringInput>;
  site_name?: InputMaybe<ModelStringInput>;
  timestamp_utc?: InputMaybe<ModelStringInput>;
};

export type ModelScanConnection = {
  __typename?: 'ModelScanConnection';
  items: Array<Maybe<Scan>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type ModelScanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelScanFilterInput>>>;
  country?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelScanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelScanFilterInput>>>;
  orthophoto_layer_url?: InputMaybe<ModelStringInput>;
  scan_date?: InputMaybe<ModelStringInput>;
  scan_name?: InputMaybe<ModelStringInput>;
  scan_path?: InputMaybe<ModelStringInput>;
  scan_path_url?: InputMaybe<ModelStringInput>;
  sensor?: InputMaybe<ModelStringInput>;
  site_name?: InputMaybe<ModelStringInput>;
  timestamp_utc?: InputMaybe<ModelStringInput>;
};

export type ModelSizeInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  ge?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  le?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
};

export enum ModelSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ModelStatusInput = {
  eq?: InputMaybe<Status>;
  ne?: InputMaybe<Status>;
};

export type ModelStatusListInput = {
  contains?: InputMaybe<Status>;
  eq?: InputMaybe<Array<InputMaybe<Status>>>;
  ne?: InputMaybe<Array<InputMaybe<Status>>>;
  notContains?: InputMaybe<Status>;
};

export type ModelStringInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']['input']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  beginsWith?: InputMaybe<Scalars['String']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  ge?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  le?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<ModelSizeInput>;
};

export type ModelSubscriptionAnalyzedEntityFilterInput = {
  algorithm?: InputMaybe<ModelSubscriptionStringInput>;
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionAnalyzedEntityFilterInput>>>;
  confidence?: InputMaybe<ModelSubscriptionStringInput>;
  flagged?: InputMaybe<ModelSubscriptionBooleanInput>;
  frequency?: InputMaybe<ModelSubscriptionFloatInput>;
  geometry?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  mean_angle_crossings?: InputMaybe<ModelSubscriptionFloatInput>;
  n_crossings?: InputMaybe<ModelSubscriptionIntInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionAnalyzedEntityFilterInput>>>;
  prefix?: InputMaybe<ModelSubscriptionStringInput>;
  probability?: InputMaybe<ModelSubscriptionFloatInput>;
  status?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionBooleanInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ModelSubscriptionChangeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionChangeFilterInput>>>;
  change?: InputMaybe<ModelSubscriptionStringInput>;
  changed_by?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionChangeFilterInput>>>;
  timestamp?: InputMaybe<ModelSubscriptionIntInput>;
};

export type ModelSubscriptionCommentFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionCommentFilterInput>>>;
  changed_by?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionCommentFilterInput>>>;
  text?: InputMaybe<ModelSubscriptionStringInput>;
  timestamp?: InputMaybe<ModelSubscriptionIntInput>;
};

export type ModelSubscriptionCrossingFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionCrossingFilterInput>>>;
  checked?: InputMaybe<ModelSubscriptionStringInput>;
  crossing_type?: InputMaybe<ModelSubscriptionStringInput>;
  crossing_url?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionCrossingFilterInput>>>;
};

export type ModelSubscriptionFloatInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  ge?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  le?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type ModelSubscriptionIdInput = {
  beginsWith?: InputMaybe<Scalars['ID']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  ge?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  le?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ModelSubscriptionIntInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  ge?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  le?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type ModelSubscriptionInterpolationFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionInterpolationFilterInput>>>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  interpolation_type?: InputMaybe<ModelSubscriptionStringInput>;
  interpolation_url?: InputMaybe<ModelSubscriptionStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionInterpolationFilterInput>>>;
};

export type ModelSubscriptionScanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelSubscriptionScanFilterInput>>>;
  country?: InputMaybe<ModelSubscriptionStringInput>;
  id?: InputMaybe<ModelSubscriptionIdInput>;
  or?: InputMaybe<Array<InputMaybe<ModelSubscriptionScanFilterInput>>>;
  orthophoto_layer_url?: InputMaybe<ModelSubscriptionStringInput>;
  scan_date?: InputMaybe<ModelSubscriptionStringInput>;
  scan_name?: InputMaybe<ModelSubscriptionStringInput>;
  scan_path?: InputMaybe<ModelSubscriptionStringInput>;
  scan_path_url?: InputMaybe<ModelSubscriptionStringInput>;
  sensor?: InputMaybe<ModelSubscriptionStringInput>;
  site_name?: InputMaybe<ModelSubscriptionStringInput>;
  timestamp_utc?: InputMaybe<ModelSubscriptionStringInput>;
};

export type ModelSubscriptionStringInput = {
  beginsWith?: InputMaybe<Scalars['String']['input']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  ge?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  le?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAnalyzedEntity?: Maybe<AnalyzedEntity>;
  createChange?: Maybe<Change>;
  createComment?: Maybe<Comment>;
  createCrossing?: Maybe<Crossing>;
  createInterpolation?: Maybe<Interpolation>;
  createScan?: Maybe<Scan>;
  deleteAnalyzedEntity?: Maybe<AnalyzedEntity>;
  deleteChange?: Maybe<Change>;
  deleteComment?: Maybe<Comment>;
  deleteCrossing?: Maybe<Crossing>;
  deleteInterpolation?: Maybe<Interpolation>;
  deleteScan?: Maybe<Scan>;
  updateAnalyzedEntity?: Maybe<AnalyzedEntity>;
  updateChange?: Maybe<Change>;
  updateComment?: Maybe<Comment>;
  updateCrossing?: Maybe<Crossing>;
  updateInterpolation?: Maybe<Interpolation>;
  updateScan?: Maybe<Scan>;
};


export type MutationCreateAnalyzedEntityArgs = {
  condition?: InputMaybe<ModelAnalyzedEntityConditionInput>;
  input: CreateAnalyzedEntityInput;
};


export type MutationCreateChangeArgs = {
  condition?: InputMaybe<ModelChangeConditionInput>;
  input: CreateChangeInput;
};


export type MutationCreateCommentArgs = {
  condition?: InputMaybe<ModelCommentConditionInput>;
  input: CreateCommentInput;
};


export type MutationCreateCrossingArgs = {
  condition?: InputMaybe<ModelCrossingConditionInput>;
  input: CreateCrossingInput;
};


export type MutationCreateInterpolationArgs = {
  condition?: InputMaybe<ModelInterpolationConditionInput>;
  input: CreateInterpolationInput;
};


export type MutationCreateScanArgs = {
  condition?: InputMaybe<ModelScanConditionInput>;
  input: CreateScanInput;
};


export type MutationDeleteAnalyzedEntityArgs = {
  condition?: InputMaybe<ModelAnalyzedEntityConditionInput>;
  input: DeleteAnalyzedEntityInput;
};


export type MutationDeleteChangeArgs = {
  condition?: InputMaybe<ModelChangeConditionInput>;
  input: DeleteChangeInput;
};


export type MutationDeleteCommentArgs = {
  condition?: InputMaybe<ModelCommentConditionInput>;
  input: DeleteCommentInput;
};


export type MutationDeleteCrossingArgs = {
  condition?: InputMaybe<ModelCrossingConditionInput>;
  input: DeleteCrossingInput;
};


export type MutationDeleteInterpolationArgs = {
  condition?: InputMaybe<ModelInterpolationConditionInput>;
  input: DeleteInterpolationInput;
};


export type MutationDeleteScanArgs = {
  condition?: InputMaybe<ModelScanConditionInput>;
  input: DeleteScanInput;
};


export type MutationUpdateAnalyzedEntityArgs = {
  condition?: InputMaybe<ModelAnalyzedEntityConditionInput>;
  input: UpdateAnalyzedEntityInput;
};


export type MutationUpdateChangeArgs = {
  condition?: InputMaybe<ModelChangeConditionInput>;
  input: UpdateChangeInput;
};


export type MutationUpdateCommentArgs = {
  condition?: InputMaybe<ModelCommentConditionInput>;
  input: UpdateCommentInput;
};


export type MutationUpdateCrossingArgs = {
  condition?: InputMaybe<ModelCrossingConditionInput>;
  input: UpdateCrossingInput;
};


export type MutationUpdateInterpolationArgs = {
  condition?: InputMaybe<ModelInterpolationConditionInput>;
  input: UpdateInterpolationInput;
};


export type MutationUpdateScanArgs = {
  condition?: InputMaybe<ModelScanConditionInput>;
  input: UpdateScanInput;
};

export type Query = {
  __typename?: 'Query';
  getAnalyzedEntity?: Maybe<AnalyzedEntity>;
  getChange?: Maybe<Change>;
  getComment?: Maybe<Comment>;
  getCrossing?: Maybe<Crossing>;
  getInterpolation?: Maybe<Interpolation>;
  getScan?: Maybe<Scan>;
  listAnalyzedEntities?: Maybe<ModelAnalyzedEntityConnection>;
  listChanges?: Maybe<ModelChangeConnection>;
  listComments?: Maybe<ModelCommentConnection>;
  listCrossings?: Maybe<ModelCrossingConnection>;
  listInterpolations?: Maybe<ModelInterpolationConnection>;
  listScans?: Maybe<ModelScanConnection>;
  searchAnalyzedEntities?: Maybe<SearchableAnalyzedEntityConnection>;
  searchChanges?: Maybe<SearchableChangeConnection>;
  searchComments?: Maybe<SearchableCommentConnection>;
  searchCrossings?: Maybe<SearchableCrossingConnection>;
  searchInterpolations?: Maybe<SearchableInterpolationConnection>;
  searchScans?: Maybe<SearchableScanConnection>;
};


export type QueryGetAnalyzedEntityArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetChangeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCommentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCrossingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetInterpolationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetScanArgs = {
  id: Scalars['ID']['input'];
};


export type QueryListAnalyzedEntitiesArgs = {
  filter?: InputMaybe<ModelAnalyzedEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListChangesArgs = {
  filter?: InputMaybe<ModelChangeFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListCommentsArgs = {
  filter?: InputMaybe<ModelCommentFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListCrossingsArgs = {
  filter?: InputMaybe<ModelCrossingFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListInterpolationsArgs = {
  filter?: InputMaybe<ModelInterpolationFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListScansArgs = {
  filter?: InputMaybe<ModelScanFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchAnalyzedEntitiesArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableAnalyzedEntityAggregationInput>>>;
  filter?: InputMaybe<SearchableAnalyzedEntityFilterInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableAnalyzedEntitySortInput>>>;
};


export type QuerySearchChangesArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableChangeAggregationInput>>>;
  filter?: InputMaybe<SearchableChangeFilterInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableChangeSortInput>>>;
};


export type QuerySearchCommentsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableCommentAggregationInput>>>;
  filter?: InputMaybe<SearchableCommentFilterInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableCommentSortInput>>>;
};


export type QuerySearchCrossingsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableCrossingAggregationInput>>>;
  filter?: InputMaybe<SearchableCrossingFilterInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableCrossingSortInput>>>;
};


export type QuerySearchInterpolationsArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableInterpolationAggregationInput>>>;
  filter?: InputMaybe<SearchableInterpolationFilterInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableInterpolationSortInput>>>;
};


export type QuerySearchScansArgs = {
  aggregates?: InputMaybe<Array<InputMaybe<SearchableScanAggregationInput>>>;
  filter?: InputMaybe<SearchableScanFilterInput>;
  from?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<SearchableScanSortInput>>>;
};

export type Scan = {
  __typename?: 'Scan';
  analyzed_entities?: Maybe<ModelAnalyzedEntityConnection>;
  country: Scalars['String']['output'];
  createdAt: Scalars['AWSDateTime']['output'];
  id: Scalars['ID']['output'];
  interpolations?: Maybe<ModelInterpolationConnection>;
  orthophoto_layer_url?: Maybe<Scalars['String']['output']>;
  scan_date: Scalars['AWSDate']['output'];
  scan_name: Scalars['String']['output'];
  scan_path: Scalars['String']['output'];
  scan_path_url?: Maybe<Scalars['String']['output']>;
  sensor?: Maybe<Scalars['String']['output']>;
  site_name: Scalars['String']['output'];
  timestamp_utc?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['AWSDateTime']['output'];
};


export type ScanAnalyzed_EntitiesArgs = {
  filter?: InputMaybe<ModelAnalyzedEntityFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type ScanInterpolationsArgs = {
  filter?: InputMaybe<ModelInterpolationFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type SearchableAggregateBucketResult = {
  __typename?: 'SearchableAggregateBucketResult';
  buckets?: Maybe<Array<Maybe<SearchableAggregateBucketResultItem>>>;
};

export type SearchableAggregateBucketResultItem = {
  __typename?: 'SearchableAggregateBucketResultItem';
  doc_count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
};

export type SearchableAggregateGenericResult = SearchableAggregateBucketResult | SearchableAggregateScalarResult;

export type SearchableAggregateResult = {
  __typename?: 'SearchableAggregateResult';
  name: Scalars['String']['output'];
  result?: Maybe<SearchableAggregateGenericResult>;
};

export type SearchableAggregateScalarResult = {
  __typename?: 'SearchableAggregateScalarResult';
  value: Scalars['Float']['output'];
};

export enum SearchableAggregateType {
  Avg = 'avg',
  Max = 'max',
  Min = 'min',
  Sum = 'sum',
  Terms = 'terms'
}

export enum SearchableAnalyzedEntityAggregateField {
  Algorithm = 'algorithm',
  Confidence = 'confidence',
  CreatedAt = 'createdAt',
  Flagged = 'flagged',
  Frequency = 'frequency',
  Geometry = 'geometry',
  Id = 'id',
  MeanAngleCrossings = 'mean_angle_crossings',
  NCrossings = 'n_crossings',
  Prefix = 'prefix',
  Probability = 'probability',
  ScanAnalyzedEntitiesId = 'scanAnalyzed_entitiesId',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type SearchableAnalyzedEntityAggregationInput = {
  field: SearchableAnalyzedEntityAggregateField;
  name: Scalars['String']['input'];
  type: SearchableAggregateType;
};

export type SearchableAnalyzedEntityConnection = {
  __typename?: 'SearchableAnalyzedEntityConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<AnalyzedEntity>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchableAnalyzedEntityFilterInput = {
  algorithm?: InputMaybe<SearchableStringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SearchableAnalyzedEntityFilterInput>>>;
  confidence?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  flagged?: InputMaybe<SearchableBooleanFilterInput>;
  frequency?: InputMaybe<SearchableFloatFilterInput>;
  geometry?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  mean_angle_crossings?: InputMaybe<SearchableFloatFilterInput>;
  n_crossings?: InputMaybe<SearchableIntFilterInput>;
  not?: InputMaybe<SearchableAnalyzedEntityFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableAnalyzedEntityFilterInput>>>;
  prefix?: InputMaybe<SearchableStringFilterInput>;
  probability?: InputMaybe<SearchableFloatFilterInput>;
  scanAnalyzed_entitiesId?: InputMaybe<SearchableIdFilterInput>;
  status?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableAnalyzedEntitySortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableAnalyzedEntitySortableFields>;
};

export enum SearchableAnalyzedEntitySortableFields {
  CreatedAt = 'createdAt',
  Flagged = 'flagged',
  Frequency = 'frequency',
  Geometry = 'geometry',
  Id = 'id',
  MeanAngleCrossings = 'mean_angle_crossings',
  NCrossings = 'n_crossings',
  Prefix = 'prefix',
  Probability = 'probability',
  ScanAnalyzedEntitiesId = 'scanAnalyzed_entitiesId',
  UpdatedAt = 'updatedAt'
}

export type SearchableBooleanFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SearchableChangeAggregateField {
  AnalyzedEntityChangesId = 'analyzedEntityChangesId',
  Change = 'change',
  ChangedBy = 'changed_by',
  CreatedAt = 'createdAt',
  Id = 'id',
  Timestamp = 'timestamp',
  UpdatedAt = 'updatedAt'
}

export type SearchableChangeAggregationInput = {
  field: SearchableChangeAggregateField;
  name: Scalars['String']['input'];
  type: SearchableAggregateType;
};

export type SearchableChangeConnection = {
  __typename?: 'SearchableChangeConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Change>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchableChangeFilterInput = {
  analyzedEntityChangesId?: InputMaybe<SearchableIdFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SearchableChangeFilterInput>>>;
  change?: InputMaybe<SearchableStringFilterInput>;
  changed_by?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableChangeFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableChangeFilterInput>>>;
  timestamp?: InputMaybe<SearchableIntFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableChangeSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableChangeSortableFields>;
};

export enum SearchableChangeSortableFields {
  AnalyzedEntityChangesId = 'analyzedEntityChangesId',
  Change = 'change',
  ChangedBy = 'changed_by',
  CreatedAt = 'createdAt',
  Id = 'id',
  Timestamp = 'timestamp',
  UpdatedAt = 'updatedAt'
}

export enum SearchableCommentAggregateField {
  AnalyzedEntityCommentsId = 'analyzedEntityCommentsId',
  ChangedBy = 'changed_by',
  CreatedAt = 'createdAt',
  Id = 'id',
  Text = 'text',
  Timestamp = 'timestamp',
  UpdatedAt = 'updatedAt'
}

export type SearchableCommentAggregationInput = {
  field: SearchableCommentAggregateField;
  name: Scalars['String']['input'];
  type: SearchableAggregateType;
};

export type SearchableCommentConnection = {
  __typename?: 'SearchableCommentConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Comment>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchableCommentFilterInput = {
  analyzedEntityCommentsId?: InputMaybe<SearchableIdFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SearchableCommentFilterInput>>>;
  changed_by?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableCommentFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableCommentFilterInput>>>;
  text?: InputMaybe<SearchableStringFilterInput>;
  timestamp?: InputMaybe<SearchableIntFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableCommentSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableCommentSortableFields>;
};

export enum SearchableCommentSortableFields {
  AnalyzedEntityCommentsId = 'analyzedEntityCommentsId',
  ChangedBy = 'changed_by',
  CreatedAt = 'createdAt',
  Id = 'id',
  Text = 'text',
  Timestamp = 'timestamp',
  UpdatedAt = 'updatedAt'
}

export enum SearchableCrossingAggregateField {
  AnalyzedEntityCrossingsId = 'analyzedEntityCrossingsId',
  Checked = 'checked',
  CreatedAt = 'createdAt',
  CrossingType = 'crossing_type',
  CrossingUrl = 'crossing_url',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type SearchableCrossingAggregationInput = {
  field: SearchableCrossingAggregateField;
  name: Scalars['String']['input'];
  type: SearchableAggregateType;
};

export type SearchableCrossingConnection = {
  __typename?: 'SearchableCrossingConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Crossing>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchableCrossingFilterInput = {
  analyzedEntityCrossingsId?: InputMaybe<SearchableIdFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SearchableCrossingFilterInput>>>;
  checked?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  crossing_type?: InputMaybe<SearchableStringFilterInput>;
  crossing_url?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableCrossingFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableCrossingFilterInput>>>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableCrossingSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableCrossingSortableFields>;
};

export enum SearchableCrossingSortableFields {
  AnalyzedEntityCrossingsId = 'analyzedEntityCrossingsId',
  CreatedAt = 'createdAt',
  CrossingType = 'crossing_type',
  CrossingUrl = 'crossing_url',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type SearchableFloatFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type SearchableIdFilterInput = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  match?: InputMaybe<Scalars['ID']['input']>;
  matchPhrase?: InputMaybe<Scalars['ID']['input']>;
  matchPhrasePrefix?: InputMaybe<Scalars['ID']['input']>;
  multiMatch?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  regexp?: InputMaybe<Scalars['ID']['input']>;
  wildcard?: InputMaybe<Scalars['ID']['input']>;
};

export type SearchableIntFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export enum SearchableInterpolationAggregateField {
  CreatedAt = 'createdAt',
  Id = 'id',
  InterpolationType = 'interpolation_type',
  InterpolationUrl = 'interpolation_url',
  ScanInterpolationsId = 'scanInterpolationsId',
  UpdatedAt = 'updatedAt'
}

export type SearchableInterpolationAggregationInput = {
  field: SearchableInterpolationAggregateField;
  name: Scalars['String']['input'];
  type: SearchableAggregateType;
};

export type SearchableInterpolationConnection = {
  __typename?: 'SearchableInterpolationConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Interpolation>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchableInterpolationFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableInterpolationFilterInput>>>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  interpolation_type?: InputMaybe<SearchableStringFilterInput>;
  interpolation_url?: InputMaybe<SearchableStringFilterInput>;
  not?: InputMaybe<SearchableInterpolationFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableInterpolationFilterInput>>>;
  scanInterpolationsId?: InputMaybe<SearchableIdFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableInterpolationSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableInterpolationSortableFields>;
};

export enum SearchableInterpolationSortableFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  InterpolationType = 'interpolation_type',
  InterpolationUrl = 'interpolation_url',
  ScanInterpolationsId = 'scanInterpolationsId',
  UpdatedAt = 'updatedAt'
}

export enum SearchableScanAggregateField {
  Country = 'country',
  CreatedAt = 'createdAt',
  Id = 'id',
  OrthophotoLayerUrl = 'orthophoto_layer_url',
  ScanDate = 'scan_date',
  ScanName = 'scan_name',
  ScanPath = 'scan_path',
  ScanPathUrl = 'scan_path_url',
  Sensor = 'sensor',
  SiteName = 'site_name',
  TimestampUtc = 'timestamp_utc',
  UpdatedAt = 'updatedAt'
}

export type SearchableScanAggregationInput = {
  field: SearchableScanAggregateField;
  name: Scalars['String']['input'];
  type: SearchableAggregateType;
};

export type SearchableScanConnection = {
  __typename?: 'SearchableScanConnection';
  aggregateItems: Array<Maybe<SearchableAggregateResult>>;
  items: Array<Maybe<Scan>>;
  nextToken?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SearchableScanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<SearchableScanFilterInput>>>;
  country?: InputMaybe<SearchableStringFilterInput>;
  createdAt?: InputMaybe<SearchableStringFilterInput>;
  id?: InputMaybe<SearchableIdFilterInput>;
  not?: InputMaybe<SearchableScanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<SearchableScanFilterInput>>>;
  orthophoto_layer_url?: InputMaybe<SearchableStringFilterInput>;
  scan_date?: InputMaybe<SearchableStringFilterInput>;
  scan_name?: InputMaybe<SearchableStringFilterInput>;
  scan_path?: InputMaybe<SearchableStringFilterInput>;
  scan_path_url?: InputMaybe<SearchableStringFilterInput>;
  sensor?: InputMaybe<SearchableStringFilterInput>;
  site_name?: InputMaybe<SearchableStringFilterInput>;
  timestamp_utc?: InputMaybe<SearchableStringFilterInput>;
  updatedAt?: InputMaybe<SearchableStringFilterInput>;
};

export type SearchableScanSortInput = {
  direction?: InputMaybe<SearchableSortDirection>;
  field?: InputMaybe<SearchableScanSortableFields>;
};

export enum SearchableScanSortableFields {
  Country = 'country',
  CreatedAt = 'createdAt',
  Id = 'id',
  OrthophotoLayerUrl = 'orthophoto_layer_url',
  ScanDate = 'scan_date',
  ScanName = 'scan_name',
  ScanPath = 'scan_path',
  ScanPathUrl = 'scan_path_url',
  Sensor = 'sensor',
  SiteName = 'site_name',
  TimestampUtc = 'timestamp_utc',
  UpdatedAt = 'updatedAt'
}

export enum SearchableSortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type SearchableStringFilterInput = {
  eq?: InputMaybe<Scalars['String']['input']>;
  exists?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  match?: InputMaybe<Scalars['String']['input']>;
  matchPhrase?: InputMaybe<Scalars['String']['input']>;
  matchPhrasePrefix?: InputMaybe<Scalars['String']['input']>;
  multiMatch?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  regexp?: InputMaybe<Scalars['String']['input']>;
  wildcard?: InputMaybe<Scalars['String']['input']>;
};

export enum Status {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Test = 'TEST'
}

export type Subscription = {
  __typename?: 'Subscription';
  onCreateAnalyzedEntity?: Maybe<AnalyzedEntity>;
  onCreateChange?: Maybe<Change>;
  onCreateComment?: Maybe<Comment>;
  onCreateCrossing?: Maybe<Crossing>;
  onCreateInterpolation?: Maybe<Interpolation>;
  onCreateScan?: Maybe<Scan>;
  onDeleteAnalyzedEntity?: Maybe<AnalyzedEntity>;
  onDeleteChange?: Maybe<Change>;
  onDeleteComment?: Maybe<Comment>;
  onDeleteCrossing?: Maybe<Crossing>;
  onDeleteInterpolation?: Maybe<Interpolation>;
  onDeleteScan?: Maybe<Scan>;
  onUpdateAnalyzedEntity?: Maybe<AnalyzedEntity>;
  onUpdateChange?: Maybe<Change>;
  onUpdateComment?: Maybe<Comment>;
  onUpdateCrossing?: Maybe<Crossing>;
  onUpdateInterpolation?: Maybe<Interpolation>;
  onUpdateScan?: Maybe<Scan>;
};


export type SubscriptionOnCreateAnalyzedEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionAnalyzedEntityFilterInput>;
};


export type SubscriptionOnCreateChangeArgs = {
  filter?: InputMaybe<ModelSubscriptionChangeFilterInput>;
};


export type SubscriptionOnCreateCommentArgs = {
  filter?: InputMaybe<ModelSubscriptionCommentFilterInput>;
};


export type SubscriptionOnCreateCrossingArgs = {
  filter?: InputMaybe<ModelSubscriptionCrossingFilterInput>;
};


export type SubscriptionOnCreateInterpolationArgs = {
  filter?: InputMaybe<ModelSubscriptionInterpolationFilterInput>;
};


export type SubscriptionOnCreateScanArgs = {
  filter?: InputMaybe<ModelSubscriptionScanFilterInput>;
};


export type SubscriptionOnDeleteAnalyzedEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionAnalyzedEntityFilterInput>;
};


export type SubscriptionOnDeleteChangeArgs = {
  filter?: InputMaybe<ModelSubscriptionChangeFilterInput>;
};


export type SubscriptionOnDeleteCommentArgs = {
  filter?: InputMaybe<ModelSubscriptionCommentFilterInput>;
};


export type SubscriptionOnDeleteCrossingArgs = {
  filter?: InputMaybe<ModelSubscriptionCrossingFilterInput>;
};


export type SubscriptionOnDeleteInterpolationArgs = {
  filter?: InputMaybe<ModelSubscriptionInterpolationFilterInput>;
};


export type SubscriptionOnDeleteScanArgs = {
  filter?: InputMaybe<ModelSubscriptionScanFilterInput>;
};


export type SubscriptionOnUpdateAnalyzedEntityArgs = {
  filter?: InputMaybe<ModelSubscriptionAnalyzedEntityFilterInput>;
};


export type SubscriptionOnUpdateChangeArgs = {
  filter?: InputMaybe<ModelSubscriptionChangeFilterInput>;
};


export type SubscriptionOnUpdateCommentArgs = {
  filter?: InputMaybe<ModelSubscriptionCommentFilterInput>;
};


export type SubscriptionOnUpdateCrossingArgs = {
  filter?: InputMaybe<ModelSubscriptionCrossingFilterInput>;
};


export type SubscriptionOnUpdateInterpolationArgs = {
  filter?: InputMaybe<ModelSubscriptionInterpolationFilterInput>;
};


export type SubscriptionOnUpdateScanArgs = {
  filter?: InputMaybe<ModelSubscriptionScanFilterInput>;
};

export type UpdateAnalyzedEntityInput = {
  algorithm?: InputMaybe<Algorithm>;
  confidence?: InputMaybe<Confidence>;
  flagged?: InputMaybe<Scalars['Boolean']['input']>;
  frequency?: InputMaybe<Scalars['Float']['input']>;
  geometry?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  mean_angle_crossings?: InputMaybe<Scalars['Float']['input']>;
  n_crossings?: InputMaybe<Scalars['Int']['input']>;
  prefix?: InputMaybe<Scalars['String']['input']>;
  probability?: InputMaybe<Scalars['Float']['input']>;
  scanAnalyzed_entitiesId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Status>;
};

export type UpdateChangeInput = {
  analyzedEntityChangesId?: InputMaybe<Scalars['ID']['input']>;
  change?: InputMaybe<Scalars['String']['input']>;
  changed_by?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  timestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
};

export type UpdateCommentInput = {
  analyzedEntityCommentsId?: InputMaybe<Scalars['ID']['input']>;
  changed_by?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['AWSTimestamp']['input']>;
};

export type UpdateCrossingInput = {
  analyzedEntityCrossingsId?: InputMaybe<Scalars['ID']['input']>;
  checked?: InputMaybe<Array<InputMaybe<Status>>>;
  crossing_type?: InputMaybe<Scalars['String']['input']>;
  crossing_url?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateInterpolationInput = {
  id: Scalars['ID']['input'];
  interpolation_type?: InputMaybe<Scalars['String']['input']>;
  interpolation_url?: InputMaybe<Scalars['String']['input']>;
  scanInterpolationsId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateScanInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  orthophoto_layer_url?: InputMaybe<Scalars['String']['input']>;
  scan_date?: InputMaybe<Scalars['AWSDate']['input']>;
  scan_name?: InputMaybe<Scalars['String']['input']>;
  scan_path?: InputMaybe<Scalars['String']['input']>;
  scan_path_url?: InputMaybe<Scalars['String']['input']>;
  sensor?: InputMaybe<Scalars['String']['input']>;
  site_name?: InputMaybe<Scalars['String']['input']>;
  timestamp_utc?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAnalyzedEntityByIdMutationVariables = Exact<{
  updateAnalyzedEntityInput: UpdateAnalyzedEntityInput;
}>;


export type UpdateAnalyzedEntityByIdMutation = { __typename?: 'Mutation', updateAnalyzedEntity?: { __typename?: 'AnalyzedEntity', flagged?: boolean | null, id: string, status?: Status | null, comments?: { __typename?: 'ModelCommentConnection', items: Array<{ __typename?: 'Comment', text?: string | null, id: string } | null> } | null } | null };

export type UpdateCrossingsByIdMutationVariables = Exact<{
  updateCrossingsInput: UpdateCrossingInput;
}>;


export type UpdateCrossingsByIdMutation = { __typename?: 'Mutation', updateCrossing?: { __typename?: 'Crossing', id: string, checked?: Array<Status | null> | null } | null };

export type GetAnalyzedEntityByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetAnalyzedEntityByIdQuery = { __typename?: 'Query', getAnalyzedEntity?: { __typename?: 'AnalyzedEntity', id: string, flagged?: boolean | null, frequency?: number | null, n_crossings?: number | null, mean_angle_crossings?: number | null, geometry?: string | null, scan?: { __typename?: 'Scan', scan_path_url?: string | null, scan_name: string, scan_date: any, site_name: string, interpolations?: { __typename?: 'ModelInterpolationConnection', items: Array<{ __typename?: 'Interpolation', interpolation_url: string, interpolation_type: string, id: string } | null> } | null } | null, comments?: { __typename?: 'ModelCommentConnection', items: Array<{ __typename?: 'Comment', text?: string | null, id: string } | null> } | null, crossings?: { __typename?: 'ModelCrossingConnection', items: Array<{ __typename?: 'Crossing', id: string, crossing_url: string, checked?: Array<Status | null> | null } | null> } | null } | null };

export type GetScansByFilterQueryVariables = Exact<{
  scanFilter: SearchableScanFilterInput;
  analyzedEntityFilter: ModelAnalyzedEntityFilterInput;
  nextToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetScansByFilterQuery = { __typename?: 'Query', searchScans?: { __typename?: 'SearchableScanConnection', nextToken?: string | null, total?: number | null, aggregateItems: Array<{ __typename?: 'SearchableAggregateResult', name: string, result?: { __typename: 'SearchableAggregateBucketResult', buckets?: Array<{ __typename?: 'SearchableAggregateBucketResultItem', doc_count: number, key: string } | null> | null } | { __typename: 'SearchableAggregateScalarResult', value: number } | null } | null>, items: Array<{ __typename?: 'Scan', country: string, scan_date: any, scan_name: string, sensor?: string | null, site_name: string, analyzed_entities?: { __typename?: 'ModelAnalyzedEntityConnection', items: Array<{ __typename?: 'AnalyzedEntity', flagged?: boolean | null, id: string, status?: Status | null, comments?: { __typename?: 'ModelCommentConnection', items: Array<{ __typename?: 'Comment', text?: string | null, id: string } | null> } | null } | null> } | null } | null> } | null };


export const UpdateAnalyzedEntityByIdDocument = gql`
    mutation UpdateAnalyzedEntityById($updateAnalyzedEntityInput: UpdateAnalyzedEntityInput!) {
  updateAnalyzedEntity(input: $updateAnalyzedEntityInput) {
    flagged
    id
    status
    comments {
      items {
        text
        id
      }
    }
  }
}
    `;
export type UpdateAnalyzedEntityByIdMutationFn = Apollo.MutationFunction<UpdateAnalyzedEntityByIdMutation, UpdateAnalyzedEntityByIdMutationVariables>;

/**
 * __useUpdateAnalyzedEntityByIdMutation__
 *
 * To run a mutation, you first call `useUpdateAnalyzedEntityByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnalyzedEntityByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnalyzedEntityByIdMutation, { data, loading, error }] = useUpdateAnalyzedEntityByIdMutation({
 *   variables: {
 *      updateAnalyzedEntityInput: // value for 'updateAnalyzedEntityInput'
 *   },
 * });
 */
export function useUpdateAnalyzedEntityByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnalyzedEntityByIdMutation, UpdateAnalyzedEntityByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnalyzedEntityByIdMutation, UpdateAnalyzedEntityByIdMutationVariables>(UpdateAnalyzedEntityByIdDocument, options);
      }
export type UpdateAnalyzedEntityByIdMutationHookResult = ReturnType<typeof useUpdateAnalyzedEntityByIdMutation>;
export type UpdateAnalyzedEntityByIdMutationResult = Apollo.MutationResult<UpdateAnalyzedEntityByIdMutation>;
export type UpdateAnalyzedEntityByIdMutationOptions = Apollo.BaseMutationOptions<UpdateAnalyzedEntityByIdMutation, UpdateAnalyzedEntityByIdMutationVariables>;
export const UpdateCrossingsByIdDocument = gql`
    mutation UpdateCrossingsById($updateCrossingsInput: UpdateCrossingInput!) {
  updateCrossing(input: $updateCrossingsInput) {
    id
    checked
  }
}
    `;
export type UpdateCrossingsByIdMutationFn = Apollo.MutationFunction<UpdateCrossingsByIdMutation, UpdateCrossingsByIdMutationVariables>;

/**
 * __useUpdateCrossingsByIdMutation__
 *
 * To run a mutation, you first call `useUpdateCrossingsByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCrossingsByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCrossingsByIdMutation, { data, loading, error }] = useUpdateCrossingsByIdMutation({
 *   variables: {
 *      updateCrossingsInput: // value for 'updateCrossingsInput'
 *   },
 * });
 */
export function useUpdateCrossingsByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCrossingsByIdMutation, UpdateCrossingsByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCrossingsByIdMutation, UpdateCrossingsByIdMutationVariables>(UpdateCrossingsByIdDocument, options);
      }
export type UpdateCrossingsByIdMutationHookResult = ReturnType<typeof useUpdateCrossingsByIdMutation>;
export type UpdateCrossingsByIdMutationResult = Apollo.MutationResult<UpdateCrossingsByIdMutation>;
export type UpdateCrossingsByIdMutationOptions = Apollo.BaseMutationOptions<UpdateCrossingsByIdMutation, UpdateCrossingsByIdMutationVariables>;
export const GetAnalyzedEntityByIdDocument = gql`
    query GetAnalyzedEntityById($id: ID!) {
  getAnalyzedEntity(id: $id) {
    id
    flagged
    frequency
    scan {
      scan_path_url
      interpolations {
        items {
          interpolation_url
          interpolation_type
          id
        }
      }
      scan_name
      scan_date
      site_name
    }
    comments {
      items {
        text
        id
      }
    }
    crossings {
      items {
        id
        crossing_url
        checked
      }
    }
    n_crossings
    mean_angle_crossings
    geometry
  }
}
    `;

/**
 * __useGetAnalyzedEntityByIdQuery__
 *
 * To run a query within a React component, call `useGetAnalyzedEntityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyzedEntityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyzedEntityByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAnalyzedEntityByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAnalyzedEntityByIdQuery, GetAnalyzedEntityByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnalyzedEntityByIdQuery, GetAnalyzedEntityByIdQueryVariables>(GetAnalyzedEntityByIdDocument, options);
      }
export function useGetAnalyzedEntityByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnalyzedEntityByIdQuery, GetAnalyzedEntityByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnalyzedEntityByIdQuery, GetAnalyzedEntityByIdQueryVariables>(GetAnalyzedEntityByIdDocument, options);
        }
export type GetAnalyzedEntityByIdQueryHookResult = ReturnType<typeof useGetAnalyzedEntityByIdQuery>;
export type GetAnalyzedEntityByIdLazyQueryHookResult = ReturnType<typeof useGetAnalyzedEntityByIdLazyQuery>;
export type GetAnalyzedEntityByIdQueryResult = Apollo.QueryResult<GetAnalyzedEntityByIdQuery, GetAnalyzedEntityByIdQueryVariables>;
export const GetScansByFilterDocument = gql`
    query getScansByFilter($scanFilter: SearchableScanFilterInput!, $analyzedEntityFilter: ModelAnalyzedEntityFilterInput!, $nextToken: String) {
  searchScans(
    filter: $scanFilter
    aggregates: {field: country, type: terms, name: "country_name"}
    nextToken: $nextToken
  ) {
    nextToken
    total
    aggregateItems {
      name
      result {
        ... on SearchableAggregateScalarResult {
          __typename
          value
        }
        ... on SearchableAggregateBucketResult {
          __typename
          buckets {
            doc_count
            key
          }
        }
      }
    }
    items {
      country
      scan_date
      scan_name
      sensor
      site_name
      analyzed_entities(filter: $analyzedEntityFilter) {
        items {
          flagged
          id
          status
          comments {
            items {
              text
              id
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetScansByFilterQuery__
 *
 * To run a query within a React component, call `useGetScansByFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScansByFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScansByFilterQuery({
 *   variables: {
 *      scanFilter: // value for 'scanFilter'
 *      analyzedEntityFilter: // value for 'analyzedEntityFilter'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useGetScansByFilterQuery(baseOptions: Apollo.QueryHookOptions<GetScansByFilterQuery, GetScansByFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScansByFilterQuery, GetScansByFilterQueryVariables>(GetScansByFilterDocument, options);
      }
export function useGetScansByFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScansByFilterQuery, GetScansByFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScansByFilterQuery, GetScansByFilterQueryVariables>(GetScansByFilterDocument, options);
        }
export type GetScansByFilterQueryHookResult = ReturnType<typeof useGetScansByFilterQuery>;
export type GetScansByFilterLazyQueryHookResult = ReturnType<typeof useGetScansByFilterLazyQuery>;
export type GetScansByFilterQueryResult = Apollo.QueryResult<GetScansByFilterQuery, GetScansByFilterQueryVariables>;