import React from 'react';
import { Container, Checkmark } from './styles';

interface Props {
  checked?: boolean;
  onChange?: (value?: boolean) => void;
  className?: string;
}

const Checkbox: React.FC<Props> = (props) => {
  const { checked, onChange, className } = props;

  const onClick = (): void => {
    onChange?.(!checked);
  };

  return (
    <Container onClick={onClick}>
      <Checkmark checked={checked} className={className} />
    </Container>
  );
};

export default Checkbox;
