import React, { useState, useRef } from 'react';
import { Arrow, Container, Dropdown, DropdownItem, Input, Label } from './styles';

interface Props {
  id: string;
  label?: string;
  value: string;
  onChange: (value: string) => void;
  options: string[];
  className?: string;
}

const DropdownInput: React.FC<Props> = (props) => {
  const { value, onChange, options, id, label, className } = props;
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const onFocus = (): void => setIsFocused(true);
  const onBlur = (): void => setIsFocused(false);
  const onChooseOption = (option: string): void => {
    onChange(option);
  };

  const focus = (): void => inputRef.current?.focus();

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(e.target.value);
  };

  return (
    <Container className={className}>
      <Label htmlFor={id}>{label}</Label>
      <Input
        id={id}
        value={value}
        onChange={onChangeInput}
        onFocus={onFocus}
        onBlur={onBlur}
        autoComplete={'off'}
        ref={inputRef}
      />
      {isFocused && options?.length !== 0 && (
        <Dropdown>
          {options.map((opt) => (
            <DropdownItem key={opt} onMouseDown={() => onChooseOption(opt)}>
              {opt}
            </DropdownItem>
          ))}
        </Dropdown>
      )}
      <Arrow checked={isFocused} onClick={focus} />
    </Container>
  );
};

export default DropdownInput;
