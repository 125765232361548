import React from 'react';
import { MapButtonsContainer, Text, Row } from './styles';
import Switch from '@components/Switch';
import SelectInput, { Option } from '@components/SelectInput';

interface Props {
  isMapLayerOn: boolean;
  onChangeMapLayerOn: (value: boolean) => void;
  isOrthophotoLayerOn: boolean;
  onChangeOrthophotoLayerOn: (value: boolean) => void;
  isScanPathLayerOn: boolean;
  onChangeScanPathLayerOn: (value: boolean) => void;
  isUtilitiesLayerOn: boolean;
  onChangeUtilitiesLayerOn: (value: boolean) => void;
  interpolationOptions: Option[];
  selectedInterpolationId: Option['key'];
  setSelectedInterpolationId: (key: Option['key']) => void;
}

const MapLayerToggles: React.FC<Props> = (props) => {
  const {
    isMapLayerOn,
    onChangeMapLayerOn,
    isOrthophotoLayerOn,
    onChangeOrthophotoLayerOn,
    isScanPathLayerOn,
    onChangeScanPathLayerOn,
    isUtilitiesLayerOn,
    onChangeUtilitiesLayerOn,
    interpolationOptions,
    selectedInterpolationId,
    setSelectedInterpolationId
  } = props;

  return (
    <MapButtonsContainer>
      <Row>
        <Switch onChange={onChangeMapLayerOn} checked={isMapLayerOn} />
        <Text>Map</Text>
      </Row>
      <Row>
        <Switch onChange={onChangeOrthophotoLayerOn} checked={isOrthophotoLayerOn} />
        <Text>Orthophoto</Text>
      </Row>
      <Row>
        <SelectInput
          selectedKey={selectedInterpolationId}
          onChange={setSelectedInterpolationId}
          options={interpolationOptions}
        />
      </Row>
      <Row>
        <Switch onChange={onChangeScanPathLayerOn} checked={isScanPathLayerOn} />
        <Text>Scan Path</Text>
      </Row>
      <Row>
        <Switch onChange={onChangeUtilitiesLayerOn} checked={isUtilitiesLayerOn} />
        <Text>Other Utilities</Text>
      </Row>
    </MapButtonsContainer>
  );
};

export default MapLayerToggles;
