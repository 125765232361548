import { getBearerToken } from '@helpers/auth';

export const fetchDataByUrl = async <T>(url: string): Promise<T> => {
  const token = await getBearerToken();
  const dataWithURL = await fetch(url, {
    headers: {
      Authorization: token
    }
  });

  const URL = await dataWithURL.json();
  const resp = await fetch(URL.url);
  return await resp.json();
};
