import React, { useEffect, useMemo, useRef, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import * as Plotly from 'plotly.js';
import { ImageOverlay } from 'react-leaflet';
import L from 'leaflet';
import { Container } from './styles';
import { Datum } from 'plotly.js';
import { getMax, getMin } from '@helpers/math';

export type InterpolationZ = Datum[][];

interface Props {
  transformedBox: [[number, number], [number, number]];
  interpolation: InterpolationZ;
  opacity?: number;
  minInterpolation?: number;
  maxInterpolation?: number;
}

const layout = {
  paper_bgcolor: 'rgba(0,0,0,0)',
  plot_bgcolor: 'rgba(0,0,0,0)',
  showlegend: false,
  xaxis: {
    visible: false,
    scaleanchor: 'y',
    scaleratio: 1
  },
  yaxis: {
    visible: false
  },
  margin: {
    l: 0,
    r: 0,
    b: 0,
    t: 0,
    pad: 0
  }
};

interface InterpolationMinMax {
  min: number;
  max: number;
}

const getMaxInterpolation = (interpolation: InterpolationZ): InterpolationMinMax => {
  const values = interpolation.reduce((previousValue, currentValue) => {
    return [...previousValue, ...currentValue];
  }, []);

  const filteredValues = values.filter((item) => !!item) as number[];
  return {
    max: getMax(filteredValues),
    min: getMin(filteredValues)
  };
};

const Interpolation: React.FC<Props> = (props) => {
  const {
    interpolation,
    transformedBox,
    opacity = 1,
    minInterpolation = 0,
    maxInterpolation = 1
  } = props;
  const ref = useRef<HTMLDivElement | null>(null);
  const [url, setURL] = useState<string | undefined>();
  const [bounds, setBounds] = useState<[[number, number], [number, number]] | undefined>();

  const { min, max } = useMemo(() => getMaxInterpolation(interpolation), [interpolation]);

  useEffect(() => {
    if (interpolation.length !== 0) {
      const zmax = maxInterpolation * (max - min) + min;
      const zmin = minInterpolation * (max - min) + min;
      const interpolationData = [
        {
          z: interpolation,
          zmax,
          zmin,
          zauto: false,
          colorscale: 'Jet',
          type: 'heatmap',
          showlegend: false,
          showscale: false
        }
      ];
      const plotElement = ref.current;
      // @ts-expect-error
      void Plotly.newPlot(plotElement, interpolationData, layout).then(function (gd) {
        void Plotly.toImage(gd, {
          format: 'png',
          width: 2000, // this values required for image witch invisible for user but image needed to generate url for interpolation
          height: 2000
        }).then(function (url) {
          setURL(url);
          setBounds(transformedBox);
        });
      });
    }
  }, [interpolation, transformedBox, maxInterpolation, minInterpolation]);

  return (
    <>
      {url !== undefined && bounds !== undefined && (
        <ImageOverlay url={url} bounds={L.latLngBounds(bounds)} opacity={opacity} />
      )}
      {/* needed for invisible image */}
      <Container ref={ref} />
    </>
  );
};

export default Interpolation;
