import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';

interface Props {
  latlng?: LatLngExpression;
  zoom: number;
}

const ZoomLocationController: React.FC<Props> = (props) => {
  const { zoom, latlng } = props;
  const map = useMap();

  useEffect(() => {
    if (latlng) {
      map.flyTo(latlng, zoom);
    }
  }, [zoom, latlng]);

  return null;
};

export default ZoomLocationController;
