import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
`;

export const Text = styled.div`
  font-family: Source Sans Pro;
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0 10px 0;
  word-wrap: break-word;
  cursor: pointer;
`;
