import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 15px;
  padding-bottom: 30px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
`;

export const Text = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
`;

export const Row = styled.div`
  display: flex;
  gap: 8px;
`;

export const Icon = styled.img``;
