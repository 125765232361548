import React from 'react';
import { Nav, NavContainer } from './styles';

interface Props {
  isOpen?: boolean;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  children: React.ReactNode;
}

const NavigationBar: React.FC<Props> = (props) => {
  const { isOpen, onClick, onMouseOver, onMouseLeave, children } = props;

  return (
    <NavContainer>
      <Nav onClick={onClick} $isOpen={isOpen} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
        {children}
      </Nav>
    </NavContainer>
  );
};

export default NavigationBar;
