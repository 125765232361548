import styled from 'styled-components';
import DropdownInput from '@components/DropdownInput';
import DateInput from '@components/DateInput';
import Checkbox from './Checkbox';

export const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

export const DropdownInputWithMargin = styled(DropdownInput)`
  margin: 20px;
`;

export const DateInputWithMargin = styled(DateInput)`
  margin: 20px;
`;

export const CheckboxWithMargin = styled(Checkbox)`
  margin: 20px;
`;
