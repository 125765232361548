import React, { useState } from 'react';
import { Container } from './styles';
import Metrics from './Metrics';
import CrossingViews, { Signal } from './CrossingViews';
import Feedback from './Feedback';
import { useEffectAsync } from '@hooks/useEffectAsync';
import { fetchDataByUrl } from '@helpers/fetchData';
import { Status, useUpdateCrossingsByIdMutation } from '@graphql/generated';

interface CrossingsI {
  signals: {
    yamplitude: Signal[];
    zamplitude: Signal[];
  };
}

interface Comment {
  id: string;
  text?: string | null;
}

interface Props {
  id?: string;
  crossingUrl?: string;
  crossingStatuses?: Array<Status | null>;
  crossingsQty?: number;
  correlationFactor?: number;
  meanCrossingAngle?: number;
  meanCrossingPower?: number;
  comments?: Array<Comment | null>;
}

const DetailsView: React.FC<Props> = (props) => {
  const {
    id,
    crossingUrl,
    crossingStatuses,
    crossingsQty,
    meanCrossingPower,
    meanCrossingAngle,
    correlationFactor,
    comments
  } = props;
  const [crossingsView, setCrossingsView] = useState<CrossingsI>();
  const [checkedGraphIndexes, setCheckedGraphIndexes] = useState<number[]>([]);
  const [updateCrossingsById, { loading }] = useUpdateCrossingsByIdMutation();

  useEffectAsync(async () => {
    if (!crossingUrl) {
      return;
    }

    const data = await fetchDataByUrl<CrossingsI>(crossingUrl);
    setCrossingsView(data);
  }, [crossingUrl]);

  const onCheckGraph = (index: number): void => {
    if (checkedGraphIndexes.includes(index)) {
      setCheckedGraphIndexes(checkedGraphIndexes.filter((item) => item !== index));
    } else {
      setCheckedGraphIndexes([...checkedGraphIndexes, index]);
    }
  };

  const onSelectNone = (): void => {
    setCheckedGraphIndexes([]);
  };

  const onSelectAll = (): void => {
    if (crossingsView) {
      setCheckedGraphIndexes(crossingsView.signals.yamplitude.map((item, index) => index));
    }
  };

  const getUpdatedStatuses = (
    newStatus: Status.Approved | Status.Rejected
  ): Array<Status | null> => {
    return (
      crossingStatuses?.map((item, index) =>
        checkedGraphIndexes.includes(index) ? newStatus : item
      ) ?? []
    );
  };

  const updateCrossingStatus = (status: Status.Approved | Status.Rejected): void => {
    if (!id) {
      return;
    }

    updateCrossingsById({
      variables: {
        updateCrossingsInput: {
          id,
          checked: getUpdatedStatuses(status)
        }
      }
    })
      .then(() => {
        onSelectNone();
      })
      .catch((e) => console.log('Error while updating crossings status', e));
  };

  const onApprove = (): void => updateCrossingStatus(Status.Approved);
  const onReject = (): void => updateCrossingStatus(Status.Rejected);

  return (
    <Container>
      <Metrics
        crossingsQty={crossingsQty?.toFixed(2)}
        correlationFactor={correlationFactor?.toFixed(2)}
        meanCrossingAngle={meanCrossingAngle?.toFixed(2)}
        meanCrossingPower={meanCrossingPower?.toFixed(2)}
      />
      <CrossingViews
        checkedGraphIds={checkedGraphIndexes}
        crossingStatuses={crossingStatuses}
        onCheckGraph={onCheckGraph}
        zamplitude={crossingsView?.signals.zamplitude}
        yamplitude={crossingsView?.signals.yamplitude}
        onApprove={onApprove}
        onReject={onReject}
        onSelectNone={onSelectNone}
        onSelectAll={onSelectAll}
        approveRejectLoading={loading}
      />
      <Feedback commentsQty={comments?.length} />
    </Container>
  );
};

export default DetailsView;
