import React from 'react';
import { Container } from './styles';

export enum ApprovalStatus {
  approved = 'approved'
}

interface Props {
  status: ApprovalStatus;
}

const Status: React.FC<Props> = (props) => {
  const { status } = props;

  switch (true) {
    case status === ApprovalStatus.approved:
      return <Container>Approved</Container>;
    default:
      return <Container>Approved</Container>;
  }
};

export default Status;
