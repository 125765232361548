import React, { useState } from 'react';
import NavigationBar from '@components/NavigationBar';
import { Container, ContentContainer, PageContainer } from '@pages/Main/styles';
import { ApprovalStatus } from '@pages/Main/Header/Status';
import Header from '@pages/Main/Header';
import MapView from '@pages/Main/MapView';
import DetailsView from '@pages/Main/DetailsView';
import ControlButtons from '@pages/Main/ControlButtons';
import {
  Status,
  useGetAnalyzedEntityByIdLazyQuery,
  useUpdateAnalyzedEntityByIdMutation
} from '@graphql/generated';
import { useEffectAsync } from '@hooks/useEffectAsync';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAnalyzedEntitiesFromScans, getFilterFormURL } from '@helpers/filterData';
import { getParamsFromObj } from '@helpers/url';
import { MAIN } from '@constants/routes';
import NavigationBarContent from '@pages/Main/NavigationBarContent';
import { useAllScansByFilterWithCountriesQuery } from '@hooks/useAllScansByFilterWithCountriesQuery';
import { Option } from '@components/SelectInput';

const Main: React.FC = () => {
  const [isMapLayerOn, setIsMapLayerOn] = useState(true);
  const [isOrthophotoLayerOn, setIsOrthophotoLayerOn] = useState(true);
  const [isScanPathLayerOn, setIsScanPathLayerOn] = useState(true);
  const [isUtilitiesLayerOn, setIsUtilitiesLayerOn] = useState(true);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [selectedInterpolationId, setSelectedInterpolationId] = useState<Option['key']>('');
  const [getData, { data }] = useGetAnalyzedEntityByIdLazyQuery();
  const [updateAnalyzedEntityById] = useUpdateAnalyzedEntityByIdMutation();
  const filter = getFilterFormURL();
  const { scans, getScans } = useAllScansByFilterWithCountriesQuery();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentId = searchParams.get('currentId') ?? '';
  const analyzedEntities = getAnalyzedEntitiesFromScans(scans);
  useEffectAsync(async () => {
    await getScans(filter);
  }, []);

  const redirectToEntity = (id: string): void => {
    const searchParams = getParamsFromObj({
      ...filter,
      currentId: id
    });
    navigate(`${MAIN}?${searchParams}`);
  };

  useEffectAsync(async () => {
    if (!currentId && analyzedEntities.length) {
      redirectToEntity(analyzedEntities[0].id); // use first entity from the list as default;
    }
  }, [currentId, analyzedEntities]);

  useEffectAsync(async () => {
    if (currentId) {
      await getData({
        variables: {
          id: currentId
        }
      });
    }
  }, [currentId]);

  const interpolationOptions: Option[] =
    data?.getAnalyzedEntity?.scan?.interpolations?.items.map((item) => {
      return {
        value: item?.interpolation_type ?? '',
        key: item?.id ?? ''
      };
    }) ?? [];

  interpolationOptions.push({
    key: '',
    value: 'NONE'
  });

  useEffectAsync(() => {
    setSelectedInterpolationId(interpolationOptions[0].key);
  }, [data]);

  const ids = analyzedEntities.map((item) => item.id);
  const goToNextAnalyzedEntity = (): void => {
    const currentIndex = ids.indexOf(currentId);
    const nextId = ids[currentIndex + 1];
    if (!nextId) {
      redirectToEntity(ids[0]); // it was last item, we need to go to first
      return;
    }

    redirectToEntity(nextId);
  };

  const goToPrevAnalyzedEntity = (): void => {
    const currentIndex = ids.indexOf(currentId);
    const prevId = ids[currentIndex - 1];
    if (prevId) {
      redirectToEntity(prevId);
    }
  };

  const goToNextWithListRefresh = (): void => {
    goToNextAnalyzedEntity();
    getScans(filter).catch((e) => console.log('Error while getting scans', e));
  };
  const updateStatus = (status: Status): void => {
    updateAnalyzedEntityById({
      variables: {
        updateAnalyzedEntityInput: {
          id: currentId,
          status
        }
      }
    })
      .then((r) => goToNextWithListRefresh())
      .catch((e) => console.log('Error while updating analyzed entity status', e));
  };

  const onClickPrevUtility = (): void => goToPrevAnalyzedEntity();
  const onClickNextUtility = (): void => goToNextAnalyzedEntity();
  const onApprove = (): void => updateStatus(Status.Approved);
  const onReject = (): void => updateStatus(Status.Rejected);

  const onFlag = (): void => {
    if (!data?.getAnalyzedEntity) {
      return;
    }
    updateAnalyzedEntityById({
      variables: {
        updateAnalyzedEntityInput: {
          id: currentId,
          flagged: !data.getAnalyzedEntity.flagged
        }
      }
    })
      .then((r) => goToNextWithListRefresh())
      .catch((e) => console.log('Error while updating analyzed entity flagged', e));
  };
  const onMouseOver = (): void => setIsOpenMenu(true);
  const onMouseLeave = (): void => setIsOpenMenu(false);
  const setNewCurrentId = (id: string): void => redirectToEntity(id);

  const utilityData = data?.getAnalyzedEntity?.geometry;
  const utilityDataJson = utilityData && JSON.parse(utilityData.replaceAll("'", '"'));
  const selectedInterpolation = data?.getAnalyzedEntity?.scan?.interpolations?.items.find(
    (item) => item && item.id === selectedInterpolationId
  );
  const crossing = data?.getAnalyzedEntity?.crossings?.items[0];
  return (
    <PageContainer>
      <NavigationBar isOpen={isOpenMenu} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
        <NavigationBarContent
          isOpen={isOpenMenu}
          analyzedEntities={analyzedEntities}
          setId={setNewCurrentId}
        />
      </NavigationBar>
      <Container>
        <Header
          status={ApprovalStatus.approved}
          utilityId={currentId}
          frequency={data?.getAnalyzedEntity?.frequency ?? undefined}
          onClickPrevUtility={onClickPrevUtility}
          onClickNextUtility={onClickNextUtility}
        />
        <ContentContainer>
          <MapView
            siteName={data?.getAnalyzedEntity?.scan?.site_name}
            scanName={data?.getAnalyzedEntity?.scan?.scan_name}
            scanDate={data?.getAnalyzedEntity?.scan?.scan_date}
            isMapLayerOn={isMapLayerOn}
            isScanPathLayerOn={isScanPathLayerOn}
            isOrthophotoLayerOn={isOrthophotoLayerOn}
            isUtilitiesLayerOn={isUtilitiesLayerOn}
            isInterpolationLayerOn={!!selectedInterpolation}
            scanPathUrl={data?.getAnalyzedEntity?.scan?.scan_path_url ?? undefined}
            interpolationUrl={selectedInterpolation?.interpolation_url}
            utilityData={utilityDataJson}
          />
          <DetailsView
            id={crossing?.id}
            crossingUrl={crossing?.crossing_url}
            crossingStatuses={crossing?.checked ?? undefined}
            crossingsQty={data?.getAnalyzedEntity?.n_crossings ?? undefined}
            correlationFactor={0}
            meanCrossingAngle={data?.getAnalyzedEntity?.mean_angle_crossings ?? undefined}
            meanCrossingPower={100}
            comments={data?.getAnalyzedEntity?.comments?.items}
          />
        </ContentContainer>
        <ControlButtons
          isMapLayerOn={isMapLayerOn}
          onChangeMapLayerOn={setIsMapLayerOn}
          isOrthophotoLayerOn={isOrthophotoLayerOn}
          onChangeOrthophotoLayerOn={setIsOrthophotoLayerOn}
          isScanPathLayerOn={isScanPathLayerOn}
          onChangeScanPathLayerOn={setIsScanPathLayerOn}
          isUtilitiesLayerOn={isUtilitiesLayerOn}
          onChangeUtilitiesLayerOn={setIsUtilitiesLayerOn}
          interpolationOptions={interpolationOptions}
          selectedInterpolationId={selectedInterpolationId}
          setSelectedInterpolationId={setSelectedInterpolationId}
          onApprove={onApprove}
          onReject={onReject}
          onFlag={onFlag}
        />
      </Container>
    </PageContainer>
  );
};

export default Main;
