import styled from 'styled-components';

export const Label = styled.label`
  position: relative;
  display: inline-block;

  width: 45px;
  height: 16px;
  margin: 0 15px 0 15px;
`;

export const CheckBox = styled.span<{ checked?: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dadee3;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: -2px;
    bottom: -4px;
    background-color: rgba(90, 89, 89, 1);
    box-shadow: 0 0 0 12px rgba(90, 89, 89, 0.21);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    transform: ${(props) => props.checked && 'translateX(25px)'};
  }
`;
