import React from 'react';
import { Container, Text } from './styles';

const Header: React.FC = () => {
  return (
    <Container>
      <Text>Configure Validation Session</Text>
    </Container>
  );
};

export default Header;
