import React from 'react';
import { Container } from './styles';
import { Status as StatusType } from '@graphql/generated';

interface Props {
  status?: StatusType | null;
}

const Status: React.FC<Props> = (props) => {
  const { status } = props;

  return <Container $status={status} />;
};

export default Status;
